import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ReportService } from 'src/app/service/report.service';
import { IhcIshInstitutionRatio } from 'src/app/models/ihc-ish-institution-ratio';
import { Color } from 'ng2-charts';
import { GenderRatio } from 'src/app/models/gender-ratio';
import { YearCount } from 'src/app/models/year-count';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  public loading = false;
  public departmentsRate: any = null;
  public departmentsRateInAllYears: any = null;

  public departmentName:string = null;

  public ihcIshInstitutionRatios: IhcIshInstitutionRatio[] = []
  public genderRatios: GenderRatio = null;
  public yearCounts: YearCount[] = [];

  public tumorStageFilters = new Array();
  public yearFilters = new Array();

  public tsfSelectedValue = null;
  public yearSelectedValue = null;

  constructor (
    private location: Location,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.initFilters();
    this.getDepartmentsRate();
    this.getDepartmentRateforAllYears();
    // this.getInstitutionAndTestRatio();
    // this.getGenderRatio();
    // this.getYearCount();
  }

  initFilters() {
    this.tumorStageFilters = [
      {label: 'All', value: 'ALL'},
      {label: 'N/A', value: 'N/A'},
      {label: 'Early', value: 'EARLY'},
      {label: 'Metastatic', value: 'METASTATIC'},
      {label: 'Early and metastatic', value: 'EARLY AND METASTATIC'}
    ];
    this.tsfSelectedValue = 'ALL';


    let firstYearFilter = {label: 'All', value: 'ALL'};
    this.yearFilters.push(firstYearFilter);

    // let year = 2021;

    let year = new Date().getFullYear();

    for (let i = 0; i < 22; i++) {
      const value = {
        label: year, value: year
      }

      this.yearFilters.push(value);
      year--;
    }

    this.yearSelectedValue = 'ALL';
  }

  getDepartmentsRate() {
    const params = {
      isWholeDataSet: true,
      isSingleYear: false,
      isTimeInterval: false,
      year: "",
      startDate: "",
      endDate: "",
      institutionId: 1
    }

    this.loading = true;
    this.reportService.getDepartmentsPositiveRateWholeRange(params).subscribe((res: any) => {
      this.departmentsRate = res;
      this.loading = false;
      
      this.filterRatioPerYearChart();
    })

  }

  getDepartmentRateforAllYears() {
    this.loading = true;
    this.reportService.getDepartmentPositiveRateInAllYears().subscribe((res: any) => {
      this.departmentsRateInAllYears = res;
      if(res !== null && res.length > 0 ) {
        this.departmentName = res[0].department.name;
      }
      this.loading = false;
    })
  }

  getInstitutionAndTestRatio() {
    this.loading = true;
    this.reportService.getInstitutionWithTestsRatio().subscribe((res: any) => {
      this.ihcIshInstitutionRatios = res;
      this.loading = false;
    })
  }

  getGenderRatio() {
    this.loading = true;
    this.reportService.getGenderRatio().subscribe((res: any) => {
      this.genderRatios = res;
      this.loading = false;
    })
  }

  getYearCount() {
    this.loading = true;
    this.reportService.getYearCount().subscribe((res: any) => {
      this.yearCounts = res;
      this.loading = false;
    })
  }

  goBack() {
    this.location.back();
  }

  onFilterChange() {
    this.filterDepartmentsRatioChart();
  }

  private filterDepartmentsRatioChart() {
    let tumorStageValue = this.tsfSelectedValue;
    let yearValue = this.yearSelectedValue;

    if(tumorStageValue === 'ALL' && yearValue === 'ALL') {
      this.getDepartmentsRate();
    } else {
      const params = {
        tumorStage: tumorStageValue,
        year: yearValue
      }

      this.loading = true;
      this.reportService.filterPositiveRatioChart(params).subscribe((res: any) => {
        this.departmentsRate = res;
        this.loading = false;
        this.filterRatioPerYearChart();
      }), err => {
        //err msg
        this.getDepartmentsRate();
      };
    }
  }

  private filterRatioPerYearChart() {
    let tumorStageValue = this.tsfSelectedValue;
    let yearValue = this.yearSelectedValue;

    if(tumorStageValue === 'ALL' && yearValue === 'ALL') {
      this.getDepartmentRateforAllYears();
    } else {
      const params = {
        tumorStage: tumorStageValue,
        year: yearValue
      }

      this.loading = true;
      this.reportService.filterPositiveRatioPerYearChart(params).subscribe((res: any) => {
        this.departmentsRateInAllYears = res;
        this.loading = false;
      }), err => {
        //err msg
        this.getDepartmentRateforAllYears();
      };
    }
  }

}
