import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLoaderComponent } from './app-loader.component';

@NgModule({
    imports: [CommonModule],
    declarations: [AppLoaderComponent],
    exports: [AppLoaderComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppLoaderModule { }