import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { PatientComponent } from './patient/patient.component';
import { AddSinglePatientComponent } from './add-single-patient/add-single-patient.component';
import { AddMultiplePatientComponent } from './add-multiple-patient/add-multiple-patient.component';
import { ReportsComponent } from './reports/reports.component';
import { EntriesComponent } from './entries/entries.component';
import { PatientIshDetailsComponent } from './patient-ish-details/patient-ish-details.component';
import { PatientAreaDetailsComponent } from './patient-area-details/patient-area-details.component';
import { EditEntriesComponent } from './edit-entries/edit-entries.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    component: UserDashboardComponent,
    children: [
      {
        path: '',
        component: MainComponent
      },
      {
        path: 'entries',
        children: [
          {
            path: '',
            component: EntriesComponent
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: PatientComponent
              },
              {
                path: 'edit',
                component: EditEntriesComponent
              },
              {
                path: 'ish',
                children: [
                  {
                    path: ':id',
                    children: [
                      {
                        path: '',
                        component: PatientIshDetailsComponent
                      },
                      {
                        path: 'area',
                        children: [
                          {
                            path: ':id',
                            component: PatientAreaDetailsComponent
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'create',
            children: [
              {
                path: 'single',
                component: AddSinglePatientComponent
              },
              {
                path: 'multiple',
                component: AddMultiplePatientComponent
              }
            ]
          }
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            component: ReportsComponent
          }
        ]
      },
      {
        path: 'changePassword',
        children: [
          {
            path: '',
            component: ChangePasswordComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRouting { }
