import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppPaginatorComponent } from './app-paginator.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, FormsModule, MaterialModule, ReactiveFormsModule],
    declarations: [AppPaginatorComponent],
    exports: [AppPaginatorComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppPaginatorModule { }