import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Entry, TestIsh, TestIhc } from '../models/entry';
import { Patient } from '../models/patient';
import { Area } from '../models/area';
import { Cluster } from '../models/cluster';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private _currentUserEntriesScope: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public readonly CurrentUserEntriesScope = this._currentUserEntriesScope.asObservable();

  private apiUrl: string;
  private headers = { 'Content-Type': 'application/json' };

  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.apiBaseUrl;
  }

  createPatient(value: any) {
    return this.httpClient
      .post(this.apiUrl + "patient/create/singleEntry", value, {
        headers: this.headers
      }).pipe(map((res: any) => res as any))
  }

  updatePatient(patientId: string, body: any) {
    return this.httpClient
      .patch(this.apiUrl + "patients/" + patientId, body, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }

  getEntriesByUserId(params: any) {
    return this.httpClient
      .get(this.apiUrl + "entries/get/byUserId", {
        headers: this.headers,
        params: params
      }).pipe(map((res: any) => {
        this._currentUserEntriesScope.next(res);
        return res;
      }));
  }

  getLatestEntriesByUserId(params: any) {
    return this.httpClient
      .get(this.apiUrl + "entries/get/latest/byUserId", {
        headers: this.headers,
        params: params
      }).pipe(map((res: any) => {
        this._currentUserEntriesScope.next(res);
        return res;
      }));
  }

  getEntriesByBulkEntryId(params: any) {
    return this.httpClient
      .get(this.apiUrl + "entries/get/byBulkEntryId", {
        headers: this.headers,
        params: params
      }).pipe(map((res: any) => {
        return res;
      }));
  }

  getOthersEntries(params: any) {
    return this.httpClient
      .get(this.apiUrl + "patients/search/getFilteredPatientsCreatedByOthers", {
        headers: this.headers,
        params: params
      }).pipe(map((res: any) => {
        return {
          entry: res._embedded && res._embedded.patients ? res._embedded.patients : ([] as Entry[]), pagination: res.page
        }
      }));
  }

  getEntry(patientId: any) {
    return this.httpClient
      .get(this.apiUrl + "patients/" + patientId + "?projection=patientWithUsers", {
        headers: this.headers,
      }).pipe(map(res => { 
        return res as Patient;
      }));
  }

  getIhcs(params: any) {
    return this.httpClient
      .get(this.apiUrl + "testIhcs/search/getFilteredIhcs", {
        headers: this.headers,
        params: params
      }).pipe(map((res: any) => {
        return {
          entry: res._embedded && res._embedded.testIhcs ? res._embedded.testIhcs : ([] as TestIhc[]), pagination: res.page
        }
      }))
  }

  createIhc(body: any) {
    return this.httpClient
      .post(this.apiUrl + "testIhcs", body, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }

  updateIhc(ishId: number, body: any) {
    return this.httpClient
    .patch(this.apiUrl + "testIhcs/" + ishId, body, {headers: this.headers})
    .pipe(map((res: any) => res as any))
  }
  
  getIshs(params: any) {
    return this.httpClient
      .get(this.apiUrl + "testIshes/search/getFilteredIshs", {
        headers: this.headers,
        params: params
      }).pipe(map((res: any) => {
        return {
          entry: res._embedded && res._embedded.testIshes ? res._embedded.testIshes : ([] as TestIsh[]), pagination: res.page
        }
      }))
  }

  getIshByIdAndCreatorId(params:any) {
    return this.httpClient
    .get(this.apiUrl + "testIshes/search/findByIdAndAndCreatedByUserId", {
      headers: this.headers,
      params: params
    }).pipe(map((res: any) => {
      return res as TestIsh;
    }))
  }

  createIsh(body: any) {
    return this.httpClient
      .post(this.apiUrl + "testIshes", body, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }

  updateIsh(ishId: number, body: any) {
    return this.httpClient
    .patch(this.apiUrl + "testIshes/" + ishId, body, {headers: this.headers})
    .pipe(map((res: any) => res as any))
  }

  createMultipleEntries(value: any) {
    return this.httpClient
      .post(this.apiUrl + "entries/create/multipleEntries", value, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }

  updateMultipleEntries(value: any) {
    return this.httpClient
      .post(this.apiUrl + "entries/update/multipleEntries", value, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }

  removePatient(id: string) {
    return this.httpClient
      .post(this.apiUrl + "patient/remove/" + id, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }

  removeBulkEntry(bulkEntryId: any, userId: any) {
    return this.httpClient
      .post(this.apiUrl + "entries/remove/bulkEntry/" + bulkEntryId +"?userId=" + userId, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }

  removeMultipleBulkEntry(value: any) {
    return this.httpClient
      .post(this.apiUrl + "entries/remove/multiple/bulkEntry", value, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }


  createArea(body: any) {
    return this.httpClient
      .post(this.apiUrl + "areas", body, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }

  getAreas(params:any) {
    return this.httpClient
    .get(this.apiUrl + "areas/search/getFilteredAreas", {
      headers: this.headers,
      params: params
    }).pipe(map((res: any) => {
      return {
        entry: res._embedded && res._embedded.areas ? res._embedded.areas : ([] as Area[]), pagination: res.page
      }
    }))
  }

  getArea(id:string) {
    return this.httpClient
    .get(this.apiUrl + "areas/" + id + "?projection=areaWithCalculations", {
      headers: this.headers
    }).pipe(map((res: any) => {
      return res as Area;
    }))
  }

  updateArea(areaId: number, body: any) {
    return this.httpClient
    .patch(this.apiUrl + "areas/" + areaId, body, {headers: this.headers})
    .pipe(map((res: any) => res as any))
  }

  getClusters(params:any) {
    return this.httpClient
    .get(this.apiUrl + "clusters/search/getFilteredClusters", {
      headers: this.headers,
      params: params
    }).pipe(map((res: any) => {
      return {
        entry: res._embedded && res._embedded.clusters ? res._embedded.clusters : ([] as Cluster[]), pagination: res.page
      }
    }))
  }

  createCluster(body: any) {
    return this.httpClient
      .post(this.apiUrl + "clusters", body, {headers: this.headers})
      .pipe(map((res: any) => res as any))
  }

  updateCluster(clusterId: number, body: any) {
    return this.httpClient
    .patch(this.apiUrl + "clusters/" + clusterId, body, {headers: this.headers})
    .pipe(map((res: any) => res as any))
  }
}