import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy, ViewChild, ElementRef, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Location, DatePipe } from '@angular/common';
import { AuthService } from '../../auth/service/auth.service';
import { PatientService } from 'src/app/service/patient.service';
import { ToastrService } from 'ngx-toastr';
import { EntryValidator } from 'src/app/shared/validators/entry.validator';
import { faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FormUtilComponent } from 'src/app/utils/form-util.component';
import { EntryIshValidator } from 'src/app/shared/validators/entry-ish.validator';
import { MatDatepicker } from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {Moment} from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TimeFrameValidator } from 'src/app/shared/validators/time-frame.validator';
import { Type } from 'src/app/models/type';
import { TypesService } from 'src/app/service/types.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-add-multiple-patient',
  templateUrl: './add-multiple-patient.component.html',
  styleUrls: ['./add-multiple-patient.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    DatePipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddMultiplePatientComponent extends FormUtilComponent implements OnInit {

  @ViewChild('createEntriesSuccessMsg', {static: false}) createEntriesSuccessMsg: ElementRef;
  @ViewChild('createEntriesErrorMsg', {static: false}) createEntriesErrorMsg: ElementRef;

  faTimes = faTimes;
  faExclamationCircle = faExclamationCircle;

  public entryFormGroup: FormGroup;
  public aditionalEntryFormGroup: FormGroup;
  public entryZeroFormGroup: FormGroup;
  public entryOneFormGroup: FormGroup;
  public entryTwoFormGroup: FormGroup;
  public entryThreeFormGroup: FormGroup;
  public entryIshOnlyFormGroup: FormGroup;

  public rows: FormArray;
  public itemForm: FormGroup;
  public isFormValid:boolean = true;
  public severalBatchesFlag:boolean = false;
  public timeFrameFlag:boolean = false;
  public emptyTableFlag:boolean = false;
  public singleEntry:boolean = false;
  public submittedInitialSettings = false;
  public submittedAditionalSettings = false;
  public submittedZero = false;
  public submittedOne = false;
  public submittedTwo = false;
  public submittedThree = false;
  public submittedIshOnly = false;

  public startDate:string = null;
  public endDate:string = null;

  public loading = false;
  private morphologyTypes:Type[] = [];
  private topographyTypes:Type[] = [];
  private tumorStagesTypes:Type[] = [];

  public minDate: Date;
  public maxDate: Date;

  public antibodyTextTypes = new Array();
  public antibodyBatchTypes = new Array();
  public ishTestTypes = new Array();

  public birthDay = null;
  public intervalsForm: FormGroup;
  public someEntryCountAdded:boolean = false;
  public emptyValuesFlag:boolean = false;

  public testIshArray :Array<String> = ['Roche: VENTANA HER2 Dual ISH DNA Probe Cocktail Assay', 
    'Roche: INFORM HER2 Dual ISH DNA Probe Cocktail assay', 'Abbott: PathVysion HER-2 DNA Probe Kit', 'Agilent (Dako): HER2 IQFISH pharmDx', 
    'Leica: Leica HER2 FISH System (PathVysion)', 'Zytovision: ZytoLight ® SPEC ERBB2/CEN 17 Dual Color Probe (FISH)', 
    'Zytovision: ZytoDot ® 2C SPEC ERBB2/CEN 17 Probe Kit (CISH)'];

  public antibodyArray:Array<String> = ['Roche: PATHWAY HER2 (4B5) Rabbit Monoclonal Primary Antibody', 'Agilent (Dako): HercepTest', 'Leica: Bond Oracle HER2 System (CB11)', 'Cell Marque: Her2/Neu (SP3)']

  constructor(private location: Location,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private patientService: PatientService,
    private toastr: ToastrService,
    private typesService: TypesService,
    private changeDetectorRef:ChangeDetectorRef,
    public datepipe: DatePipe
  ) {
    super();
    this.entryFormGroup = this.getEntryForm();
    this.aditionalEntryFormGroup = this.getAditionalEntryForm();
    this.entryZeroFormGroup = this.getEntryZeroFormGroup();
    this.entryOneFormGroup = this.getEntryOneFormGroup();
    this.entryTwoFormGroup = this.getEntryTwoFormGroup();
    this.entryThreeFormGroup = this.getEntryThreeFormGroup();
    this.entryIshOnlyFormGroup = this.getEntryIshOnlyFormGroup();

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(1999, 0, 1);
    // this.minDate = new Date(currentYear - 21, 0, 1);
    this.maxDate = new Date(currentYear + 1, 0, 0);
   }

  ngOnInit() {
    this.getTypes();
  }

  goBack() {
    this.location.back();
  }

  getTypes() {
    this.getMorphologyTypes();
    this.getTopographyTypes();
    this.getTumorStagesTypes();
    this.setupAntibodyTextType();
    this.setupishTestType();
  }

  sortyTypes(typeArray:Type[]) {
    let lastElement = typeArray[typeArray.length - 1];
    let sortedArray:Type[] = [];

    sortedArray.push(lastElement);

    for (var i = 0; i < typeArray.length - 1; i++) {
      sortedArray.push(typeArray[i]);
    }

    return sortedArray;
  }

  getMorphologyTypes() {
    this.loading = true;
    this.typesService.CurrentMorphologyTypesScope.subscribe((res:Type[]) => {
      if(res !== null && res.length > 0) {
        this.morphologyTypes = this.sortyTypes(res);

        //let unknownElement = this.morphologyTypes.find(x => x.type == "N/A");
        //this.morphologyTypes = [];
        //this.morphologyTypes.push(unknownElement);
        //this.morphologyTypes =res;
      } else {
        this.loadMorphologyTypes();
      }

      this.loading = false;
    })
  }

  loadMorphologyTypes() {
    this.loading = true;
    this.typesService.getMorphologyTypes().subscribe((res:Type[]) => {
      this.morphologyTypes = this.sortyTypes(res);
      
      //let unknownElement = this.morphologyTypes.find(x => x.type == "N/A");
      //this.morphologyTypes = [];
      //this.morphologyTypes.push(unknownElement);

      this.loading = false;
    })
  }

  getTopographyTypes() {
    this.loading = true;
    this.typesService.CurrentTopographyTypesScope.subscribe((res:Type[]) => {
      if(res !== null && res.length > 0) {
        this.topographyTypes = this.sortyTypes(res);
        this.temporaryMethodToRemoveTopTypes();
      } else {
        this.loadTopographyTypes();
      }

      this.loading = false;
    })
  }

  loadTopographyTypes() {
    this.loading = true;
    this.typesService.getTopographyTypes().subscribe((res:Type[]) => {
      this.topographyTypes = this.sortyTypes(res);
      this.temporaryMethodToRemoveTopTypes();
      this.loading = false;
    })
  }

  temporaryMethodToRemoveTopTypes() {
    let el1:Type = this.topographyTypes.find(x => x.type === "GASTRIC SURGICAL SPECIMEN");
    const index1: number = this.topographyTypes.indexOf(el1);
    if(index1 > -1) {
      this.topographyTypes.splice(index1, 1);
    }

    let el2:Type = this.topographyTypes.find(x => x.type === "GASTRIC BIOPSY");
    const index2: number = this.topographyTypes.indexOf(el2);
    if(index2 > -1) {
      this.topographyTypes.splice(index2, 1);
    }

    let el3:Type = this.topographyTypes.find(x => x.type === "OTHER");
    const index3: number = this.topographyTypes.indexOf(el3);
    if(index3 > -1) {
      this.topographyTypes.splice(index3, 1);
    }
  }

  getTumorStagesTypes() {
    this.loading = true;
    this.typesService.CurrentTumorStageTypesScope.subscribe((res:Type[]) => {
      if(res !== null && res.length > 0) {
        this.tumorStagesTypes = res;
      } else {
        this.loadTumorStagesTypes();
      }

      this.loading = false;
    })
  }

  loadTumorStagesTypes() {
    this.loading = true;
    this.typesService.getTumorStageTypes().subscribe((res:Type[]) => {
      this.tumorStagesTypes = res;
      this.loading = false;
    })
  }

  setupAntibodyTextType() {
    const antibodyTextItem = {
      label: "N/A",
      type: "N/A"
    }
    this.antibodyTextTypes.push(antibodyTextItem);

    this.antibodyArray.forEach(antibody => {
      const item = {label: antibody, type: antibody}
      this.antibodyTextTypes.push(item);
    })
  }

  setupishTestType() {
    const ishTestItem = {
      label: "N/A",
      type: "N/A"
    }
    this.ishTestTypes.push(ishTestItem);

    this.testIshArray.forEach(testIsh => {
      const item = {label: testIsh, type: testIsh};
      this.ishTestTypes.push(item);
    })
  }

  private getEntryForm() {
    return this.formBuilder.group({
      tumorStageInputCtrl: [null, [Validators.required]],
      timeFrameFormGroup: this.formBuilder.group({
        timeFrameTypeInputCtrl: [false],
        wholeYearInputCtrl: [null]
      })
    }, {validators: TimeFrameValidator("timeFrameFormGroup", "timeFrameTypeInputCtrl", "wholeYearInputCtrl")});
  }

  private getAditionalEntryForm() {
    return this.formBuilder.group({
      genderInputCtrl: ['N/A'],
      morphologyInputCtrl: ['N/A'],
      topographyInputCtrl: ['N/A'],
      ishTestInputCtrl: ['N/A'],
      memoInputCtrl: [null],
      bornYearInputCtrl: [null],
      topographicLocationInputCtrl: [null],
      antibodyFormGroup: this.formBuilder.group({
        antibodyTextInputCtrl: ['N/A', [Validators.required]],
        antibodyBatchInputCtrl: ['N/A', [Validators.required]],
        severalBatchesInputCtrl: [false]
      })
    });
  }

  private getEntryZeroFormGroup() {
    return this.formBuilder.group({
      countZeroInputCtrl: [null, [Validators.min(0)]],
      ishZeroFormGroup: this.formBuilder.group({
        ishCountZeroInputCtrl: [null, [Validators.min(0)]],
        ishPositiveZeroInputCtrl: [null, [Validators.min(0)]],
        ishEquivocalZeroInputCtrl: [null, [Validators.min(0)]]
      })
    }, {validator: EntryValidator('countZeroInputCtrl', 'ishZeroFormGroup', 'ishCountZeroInputCtrl', 'ishPositiveZeroInputCtrl', 'ishEquivocalZeroInputCtrl')});
  }

  private getEntryOneFormGroup() {
    return this.formBuilder.group({
      countOneInputCtrl: [null, [Validators.min(0)]],
      ishOneFormGroup: this.formBuilder.group({
        ishCountOneInputCtrl: [null, [Validators.min(0)]],
        ishPositiveOneInputCtrl: [null, [Validators.min(0)]],
        ishEquivocalOneInputCtrl: [null, [Validators.min(0)]]
      })
    }, {validator: EntryValidator('countOneInputCtrl', 'ishOneFormGroup', 'ishCountOneInputCtrl', 'ishPositiveOneInputCtrl', 'ishEquivocalOneInputCtrl')});
  }

  private getEntryTwoFormGroup() {
    return this.formBuilder.group({
      countTwoInputCtrl: [null, [Validators.min(0)]],
      ishTwoFormGroup: this.formBuilder.group({
        ishCountTwoInputCtrl: [null, [Validators.min(0)]],
        ishPositiveTwoInputCtrl: [null, [Validators.min(0)]],
        ishEquivocalTwoInputCtrl: [null, [Validators.min(0)]]
      })
    }, {validator: EntryValidator('countTwoInputCtrl', 'ishTwoFormGroup', 'ishCountTwoInputCtrl', 'ishPositiveTwoInputCtrl', 'ishEquivocalTwoInputCtrl')});
  }

  private getEntryThreeFormGroup() {
    return this.formBuilder.group({
      countThreeInputCtrl: [null, [Validators.min(0)]],
      ishThreeFormGroup: this.formBuilder.group({
        ishCountThreeInputCtrl: [null, [Validators.min(0)]],
        ishPositiveThreeInputCtrl: [null, [Validators.min(0)]],
        ishEquivocalThreeInputCtrl: [null, [Validators.min(0)]]
      })
    }, {validator: EntryValidator('countThreeInputCtrl', 'ishThreeFormGroup', 'ishCountThreeInputCtrl', 'ishPositiveThreeInputCtrl', 'ishEquivocalThreeInputCtrl')});
  }

  private getEntryIshOnlyFormGroup() {
    return this.formBuilder.group({
      ishOnlyCountInputCtrl: [null, [Validators.min(0)]],
      ishOnlyPositiveInputCtrl: [null, [Validators.min(0)]],
      ishOnlyEquivocalInputCtrl: [null, [Validators.min(0)]]
    }, {validator: EntryIshValidator('ishOnlyCountInputCtrl', 'ishOnlyPositiveInputCtrl', 'ishOnlyEquivocalInputCtrl')});
  }

  private getEntryFormInput() {
    let timeFrames = this.getInputValue(this.entryFormGroup, 'timeFrameFormGroup');
    const data: any = {
      tumorStage: this.getInputValue(this.entryFormGroup, 'tumorStageInputCtrl'),
      timeInterval: timeFrames.timeFrameTypeInputCtrl,
      year: timeFrames.wholeYearInputCtrl,
      startDate: timeFrames.timeIntervalStartInputCtrl,
      endDate: timeFrames.timeIntervalEndInputCtrl
    }
    return data;
  }

  private getAditionalEntryFormInput() {
    let antibodyFrames = this.getInputValue(this.aditionalEntryFormGroup, 'antibodyFormGroup');
    const data: any = {
      gender: this.getInputValue(this.aditionalEntryFormGroup, 'genderInputCtrl'),
      morphology: this.getInputValue(this.aditionalEntryFormGroup, 'morphologyInputCtrl'),
      topography: this.getInputValue(this.aditionalEntryFormGroup, 'topographyInputCtrl'),
      ishTest: this.getInputValue(this.aditionalEntryFormGroup, 'ishTestInputCtrl'),
      memo: this.getInputValue(this.aditionalEntryFormGroup, 'memoInputCtrl'),
      topographicLocation: this.getInputValue(this.aditionalEntryFormGroup, 'topographicLocationInputCtrl'), 
      bornYear: this.getInputValue(this.aditionalEntryFormGroup, 'bornYearInputCtrl'),
      antibodyText: antibodyFrames.antibodyTextInputCtrl,
      antibodyBatch: antibodyFrames.antibodyBatchInputCtrl,
      severalBatches: antibodyFrames.severalBatchesInputCtrl
    }
    return data;
  }

  private getEntryZeroFormInput() {
    let ishsZero = this.getInputValue(this.entryZeroFormGroup, 'ishZeroFormGroup');
    const data: any = {
      count: this.getInputValue(this.entryZeroFormGroup, 'countZeroInputCtrl'),
      ishCount: ishsZero.ishCountZeroInputCtrl,
      ishPositive: ishsZero.ishPositiveZeroInputCtrl,
      ishEquivocal: ishsZero.ishEquivocalZeroInputCtrl
    }
    return data;
  }

  private getEntryOneFormInput() {
    let ishsOne = this.getInputValue(this.entryOneFormGroup, 'ishOneFormGroup');
    const data: any = {
      count: this.getInputValue(this.entryOneFormGroup, 'countOneInputCtrl'),
      ishCount: ishsOne.ishCountOneInputCtrl,
      ishPositive: ishsOne.ishPositiveOneInputCtrl,
      ishEquivocal: ishsOne.ishEquivocalOneInputCtrl
    }
    return data;
  }

  private getEntryTwoFormInput() {
    let ishsTwo = this.getInputValue(this.entryTwoFormGroup, 'ishTwoFormGroup');
    const data: any = {
      count: this.getInputValue(this.entryTwoFormGroup, 'countTwoInputCtrl'),
      ishCount: ishsTwo.ishCountTwoInputCtrl,
      ishPositive: ishsTwo.ishPositiveTwoInputCtrl,
      ishEquivocal: ishsTwo.ishEquivocalTwoInputCtrl
    }
    return data;
  }

  private getEntryThreeFormInput() {
    let ishsThree = this.getInputValue(this.entryThreeFormGroup, 'ishThreeFormGroup');
    const data: any = {
      count: this.getInputValue(this.entryThreeFormGroup, 'countThreeInputCtrl'),
      ishCount: ishsThree.ishCountThreeInputCtrl,
      ishPositive: ishsThree.ishPositiveThreeInputCtrl,
      ishEquivocal: ishsThree.ishEquivocalThreeInputCtrl
    }
    return data;
  }

  private getEntryIshOnlyFormInput() {
    const data: any = {
      ishCount: this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyCountInputCtrl'),
      ishPositive: this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyPositiveInputCtrl'),
      ishEquivocal: this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyEquivocalInputCtrl')
    }
    return data;
  }

  get controlsInitial() { return this.entryFormGroup.controls; }

  get controlsTimeFrame() { 
    const insideForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    return insideForm.controls; 
  }

  get controlsAntibody() { 
    const insideForm:FormGroup = this.aditionalEntryFormGroup.controls["antibodyFormGroup"] as FormGroup;
    return insideForm.controls; 
  }

  get controlsAditional() { 
    return this.aditionalEntryFormGroup.controls;
  }


  get controlsZero() { return this.entryZeroFormGroup.controls; }
  get controlsOne() { return this.entryOneFormGroup.controls; }
  get controlsTwo() { return this.entryTwoFormGroup.controls; }
  get controlsThree() { return this.entryThreeFormGroup.controls; }
  get controlsIshOnly() { return this.entryIshOnlyFormGroup.controls; }

  onSubmit() {
    this.submittedInitialSettings = true;
    this.submittedAditionalSettings = true;
    if(!this.entryFormGroup.valid) {
      return;
    }

    if(!this.aditionalEntryFormGroup.valid) {
      return;
    }

    if(this.timeFrameFlag && this.intervalsForm !== null && !this.intervalsForm.valid) {
      return;
    }

    this.checkSubmitts();

    this.checkIfAdded(null);

    if(!this.someEntryCountAdded) {
      return;
    }

    if((this.submittedZero && !this.entryZeroFormGroup.valid) || (this.submittedOne && !this.entryOneFormGroup.valid) 
      || (this.submittedTwo && !this.entryTwoFormGroup.valid) || (this.submittedThree && !this.entryThreeFormGroup.valid) 
      || (this.submittedIshOnly && !this.entryIshOnlyFormGroup.valid) ) {
      return;
    }

    if(this.submittedZero || this.submittedOne || this.submittedTwo || this.submittedThree || this.submittedIshOnly) {
      this.submittedAction();
    } {
      // this.emptyTableFlag = true;
      // TODO: show empty table msg
    }

  }

  submittedAction() {
    let entryInitials = null;
    let aditionalEntryInitials = null;
    let zeroValue = null;
    let oneValue = null;
    let twoValue = null;
    let threeValue = null;
    let ishOnlyValue = null;

    entryInitials = this.getEntryFormInput();
    aditionalEntryInitials = this.getAditionalEntryFormInput();
    
    const initialData = {
      gender: ((this.singleEntry) ? aditionalEntryInitials.gender : 'N/A'),
      morphology: ((this.singleEntry) ? aditionalEntryInitials.morphology : 'N/A'),
      topography: ((this.singleEntry) ? aditionalEntryInitials.topography : 'N/A'),
      tumorStage: entryInitials.tumorStage,
      timeInterval: entryInitials.timeInterval,
      year: null,
      startDate: null,
      endDate: null,
      antibodyBatch: aditionalEntryInitials.antibodyBatch,
      antibodyText: aditionalEntryInitials.antibodyText,
      severalBatches: aditionalEntryInitials.severalBatches,
      ishTest: aditionalEntryInitials.ishTest,
      memo: aditionalEntryInitials.memo,
      bornYear: ((this.singleEntry) ? aditionalEntryInitials.bornYear : null),
      topographicLocation: ((this.singleEntry) ? aditionalEntryInitials.topographicLocation : null) 
    }

    if(!initialData.timeInterval && entryInitials.year !== null) {
      initialData.year = entryInitials.year.year();
    }

    if(initialData.timeInterval && this.startDate !== null && this.endDate !== null) {
      initialData.startDate = this.startDate;
      initialData.endDate = this.endDate;
    }

    if((!initialData.timeInterval && entryInitials.year == null) || (initialData.timeInterval && (initialData.startDate == null || initialData.endDate == null))) {
      return;
    }

    if(initialData.bornYear) {
      const ctrlValue = moment(initialData.bornYear);
      initialData.bornYear = ctrlValue.year();
    }

    if(this.submittedZero && this.entryZeroFormGroup.valid) {
      zeroValue = this.getEntryZeroFormInput();
      
      if(zeroValue != null) {
        if(zeroValue.ishCount == 0) {
          zeroValue.ishCount = null;
        }
        if(zeroValue.ishEquivocal == 0) {
          zeroValue.ishEquivocal = null;
        }
        if(zeroValue.ishPositive == 0) {
          zeroValue.ishPositive = null;
        }
      }

      if(zeroValue.count == null && zeroValue.ishCount == null && zeroValue.ishEquivocal == null && zeroValue.ishPositive == null) {
        zeroValue = null;
      }

    } 
    
    if(this.submittedOne && this.entryOneFormGroup.valid) {
      oneValue = this.getEntryOneFormInput();

      if(oneValue != null) {
        if(oneValue.ishCount == 0) {
          oneValue.ishCount = null;
        }
        if(oneValue.ishEquivocal == 0) {
          oneValue.ishEquivocal = null;
        }
        if(oneValue.ishPositive == 0) {
          oneValue.ishPositive = null;
        }
      }

      if(oneValue.count == null && oneValue.ishCount == null && oneValue.ishEquivocal == null && oneValue.ishPositive == null) {
        oneValue = null;
      }
    }

    if(this.submittedTwo && this.entryTwoFormGroup.valid) {
      twoValue = this.getEntryTwoFormInput();

      if(twoValue != null) {
        if(twoValue.ishCount == 0) {
          twoValue.ishCount = null;
        }
        if(twoValue.ishEquivocal == 0) {
          twoValue.ishEquivocal = null;
        }
        if(twoValue.ishPositive == 0) {
          twoValue.ishPositive = null;
        }
      }

      if(twoValue.count == null && twoValue.ishCount == null && twoValue.ishEquivocal == null && twoValue.ishPositive == null) {
        twoValue = null;
      }
    }

    if(this.submittedThree && this.entryThreeFormGroup.valid) {
      threeValue = this.getEntryThreeFormInput();

      if(threeValue != null) {
        if(threeValue.ishCount == 0) {
          threeValue.ishCount = null;
        }
        if(threeValue.ishEquivocal == 0) {
          threeValue.ishEquivocal = null;
        }
        if(threeValue.ishPositive == 0) {
          threeValue.ishPositive = null;
        }
      }

      if(threeValue.count == null && threeValue.ishCount == null && threeValue.ishEquivocal == null && threeValue.ishPositive == null) {
        threeValue = null;
      }
    }

    if(this.submittedIshOnly && this.entryIshOnlyFormGroup.valid) {
      ishOnlyValue = this.getEntryIshOnlyFormInput()

      if(ishOnlyValue != null) {
        if(ishOnlyValue.ishEquivocal == 0) {
          ishOnlyValue.ishEquivocal = null;
        }
        if(ishOnlyValue.ishPositive == 0) {
          ishOnlyValue.ishPositive = null;
        }
      }

      if(ishOnlyValue.ishCount == null && ishOnlyValue.ishEquivocal == null && ishOnlyValue.ishPositive == null) {
        ishOnlyValue = null;
      }
  
    }

    this.saveEntries(initialData, zeroValue, oneValue, twoValue, threeValue, ishOnlyValue);
  }

  checkSubmitts() {
    this.checkSubmittedZero();
    this.checkSubmittedOne();
    this.checkSubmittedTwo();
    this.checkSubmittedThree();
    this.checkSubmittedIshOnly();
  }

  checkSubmittedZero() {
    let data = this.getEntryZeroFormInput();
    if(data.count == null && data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedZero = false;
    } else {
      this.submittedZero = true;
    }
  }

  checkSubmittedOne() {
    let data = this.getEntryOneFormInput();
    if(data.count == null && data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedOne = false;
    } else {
      this.submittedOne = true;
    }
  }

  checkSubmittedTwo() {
    let data = this.getEntryTwoFormInput();
    if(data.count == null && data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedTwo = false;
    } else {
      this.submittedTwo = true;
    }
  }

  checkSubmittedThree() {
    let data = this.getEntryThreeFormInput();
    if(data.count == null && data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedThree = false;
    } else {
      this.submittedThree = true;
    }
  }

  checkSubmittedIshOnly() {
    let data = this.getEntryIshOnlyFormInput();
    if(data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedIshOnly = false;
    } else {
      this.submittedIshOnly = true;
    }
  }

  private saveEntries(initialData:any, zeroValue:any, oneValue:any, twoValue:any, threeValue:any, ishOnlyValue:any) {
    
    const body = {
      initialEntryValues: initialData,
      resultZero: zeroValue,
      resultOne: oneValue,
      resultTwo: twoValue,
      resultThree: threeValue,
      resultIshOnly: ishOnlyValue
    }
    
    this.patientService.createMultipleEntries(body).subscribe(res => {
      this.toastr.success(this.createEntriesSuccessMsg.nativeElement.textContent);
      this.goBack();
    }, err => {
      this.toastr.error(this.createEntriesErrorMsg.nativeElement.textContent);
    })
  }

  chosenStartYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    const timeFrameForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    timeFrameForm.get("timeIntervalStartInputCtrl").setValue(ctrlValue);
  }

  chosenStartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonth.month());
    const timeFrameForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    timeFrameForm.get("timeIntervalStartInputCtrl").setValue(ctrlValue);
    datepicker.close();
  }

  chosenEndYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    const timeFrameForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    timeFrameForm.get("timeIntervalEndInputCtrl").setValue(ctrlValue);
    datepicker.close();
  }

  chosenWholeYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    const timeFrameForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    timeFrameForm.get("wholeYearInputCtrl").setValue(ctrlValue);
    datepicker.close();
  }

  changeTimeFrme() {
    const timeFrameForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    this.timeFrameFlag = !timeFrameForm.get("timeFrameTypeInputCtrl").value;
  }

  changeSeveralBatches() {
    const antibodyForm:FormGroup = this.aditionalEntryFormGroup.controls["antibodyFormGroup"] as FormGroup;
    this.severalBatchesFlag = !antibodyForm.get("severalBatchesInputCtrl").value;
  }

  changeSeveralBatchesToFalse() {
    const antibodyForm:FormGroup = this.aditionalEntryFormGroup.controls["antibodyFormGroup"] as FormGroup;
    antibodyForm.get("severalBatchesInputCtrl").setValue(false);
    this.severalBatchesFlag = false;
  }

  setStartIntervalDate(value:any) {
    let valueToDate = new Date(value);
    let valueToDateFormatted = this.datepipe.transform(valueToDate, 'yyyy-MM-dd');
    this.startDate = valueToDateFormatted;
  }

  setEndIntervalDate(value:any) {
    let valueToDate = new Date(value);
    let valueToDateFormatted = this.datepipe.transform(valueToDate, 'yyyy-MM-dd');
    this.endDate = valueToDateFormatted;
  }

  checkIfSingleEntry(value:any) {
    let zero = (this.getInputValue(this.entryZeroFormGroup, 'countZeroInputCtrl') !== null) ? this.getInputValue(this.entryZeroFormGroup, 'countZeroInputCtrl') : 0;
    let one = (this.getInputValue(this.entryOneFormGroup, 'countOneInputCtrl') !== null) ? this.getInputValue(this.entryOneFormGroup, 'countOneInputCtrl') : 0;
    let two = (this.getInputValue(this.entryTwoFormGroup, 'countTwoInputCtrl') !== null) ? this.getInputValue(this.entryTwoFormGroup, 'countTwoInputCtrl') : 0;
    let three = (this.getInputValue(this.entryThreeFormGroup, 'countThreeInputCtrl') !== null) ? this.getInputValue(this.entryThreeFormGroup, 'countThreeInputCtrl') : 0;
    let ishOnly = (this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyCountInputCtrl') !== null) ? this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyCountInputCtrl') : 0;
    
    if((zero == 1 && one == 0 && two == 0 && three == 0 && ishOnly == 0) 
      || (zero == 0 && one == 1 && two == 0 && three == 0 && ishOnly == 0) 
      || (zero == 0 && one == 0 && two == 1 && three == 0 && ishOnly == 0) 
      || (zero == 0 && one == 0 && two == 0 && three == 1 && ishOnly == 0) 
      || (zero == 0 && one == 0 && two == 0 && three == 0 && ishOnly == 1)) {
        this.singleEntry = true;
        this.changeSeveralBatchesToFalse();
    } else {
      this.singleEntry = false;
    }
  }

  setBirthdayDate(value:any) {
    this.birthDay = value;
  }

  chosenWholeBirthYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    this.aditionalEntryFormGroup.get("bornYearInputCtrl").setValue(ctrlValue);
    datepicker.close();
  }

  checkIntervalsValidation(value:any) {
    this.intervalsForm = value;
  }

  checkIfAdded(value:any) {
    let zero = (this.getInputValue(this.entryZeroFormGroup, 'countZeroInputCtrl') !== null) ? this.getInputValue(this.entryZeroFormGroup, 'countZeroInputCtrl') : null;
    let one = (this.getInputValue(this.entryOneFormGroup, 'countOneInputCtrl') !== null) ? this.getInputValue(this.entryOneFormGroup, 'countOneInputCtrl') : null;
    let two = (this.getInputValue(this.entryTwoFormGroup, 'countTwoInputCtrl') !== null) ? this.getInputValue(this.entryTwoFormGroup, 'countTwoInputCtrl') : null;
    let three = (this.getInputValue(this.entryThreeFormGroup, 'countThreeInputCtrl') !== null) ? this.getInputValue(this.entryThreeFormGroup, 'countThreeInputCtrl') : null;
    let ishOnly = (this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyCountInputCtrl') !== null) ? this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyCountInputCtrl') : null;
    
    if((zero != null && zero != 0) || (one != null && one != 0) || (two != null && two != 0) || (three != null && three != 0) || (ishOnly != null &&  ishOnly != 0)) {
      this.someEntryCountAdded = true;
      this.emptyValuesFlag = false;
    } else {
      this.someEntryCountAdded = false;
      this.emptyValuesFlag = true;
    }
  }

}