import { Component, OnInit, Input } from '@angular/core';
import { Color } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-departments-positive-ratio-chart',
  templateUrl: './departments-positive-ratio-chart.component.html',
  styleUrls: ['./departments-positive-ratio-chart.component.css']
})
export class DepartmentsPositiveRatioChartComponent implements OnInit {

  @Input() ratios:any;

  public chartDataArray: any[] = [];

  constructor() { }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      xAxes: [{
          maxBarThickness: 70,
          scaleLabel: {
            display: true,
            labelString: 'Departments'
         },
         ticks: {
          stepSize: 1,
          min: 0,
          maxRotation: 90,
          minRotation: 90,
          autoSkip: false
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          max: 25,
          min: 0,
          stepSize: 10,
        },
        scaleLabel: {
          display: true,
          labelString: 'Positivity Rate %'
       }
      }]
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let value = Number(tooltipItem.value).toFixed(1);
          return value.toString();
        }
      }
    }
  };

  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartLabels = [];
  public barChartColors: Color[] = [{ backgroundColor: 'rgb(0, 153, 0)' }];

  public barChartData: ChartDataSets[] = [
    {
      fill: false,
      type: 'line',
      radius: 0,
      hoverRadius: 0,
      showLine: false,
      stack: "1"
    }
  ];

  ngOnInit() {
    if(this.ratios.departmentRatios !== null) {
      this.prepareChartDataArray();
    }

    if(this.chartDataArray.length > 0) {
      this.calculateScale();
      this.setBarValues();
    }
  }

  calculateScale() {
    let valueArray = new Array();
    this.chartDataArray.forEach(ratio => {
      valueArray.push(ratio.value);
    })

    let maxValue = valueArray.reduce((a:any, b:any) => Math.max(a, b));

    if(maxValue > 25) {
      let maxPeak = Math.ceil((maxValue + 10) / 10) * 10
      this.barChartOptions.scales.yAxes[0].ticks.max = maxPeak;
    }
  }

  setBarValues() {
    this.chartDataArray.forEach(ratio => {
      this.barChartLabels.push(ratio.label);
    })

    let dataArray = new Array();
    let colorArray = new Array();

    this.chartDataArray.forEach(ratio => {
      dataArray.push(ratio.value);
      if(ratio.isUserDepartment) {
        colorArray.push('rgb(244, 123, 42)');
      } else if(ratio.isCountryRate) {
        colorArray.push('rgb(254, 190, 5)');
      } else {
        colorArray.push('rgb(63, 114, 193)')
      }
    })

    const barsData = {
      label: "Rate",
      backgroundColor: colorArray,
      data: dataArray,
      stack: "2"
    }

    this.barChartData.push(barsData);

  }

  prepareChartDataArray() {
    let newRatioArray = new Array();

    this.ratios.departmentRatios.forEach(ratio => {
      if(ratio.positiveRateDTO.type == "PERCENT" && ratio.positiveRateDTO.value > 0) {
        let label = null;
        if(ratio.department.alias !== null) {
          label = ratio.department.alias;
        } else {
          label = ratio.department.name;
        }

        let item = {
          value: ratio.positiveRateDTO.value,
          isUserDepartment: ratio.isUserDepartment,
          isCountryRate: false,
          label: label
        }

        newRatioArray.push(item);
      }
    })

    if(this.ratios.countryRatio !== null) {
      let countryRatio = this.ratios.countryRatio;
      let item = {
        value: countryRatio.positiveRateDTO.value,
        isUserDepartment: false,
        isCountryRate: true,
        label: countryRatio.alias
      }
      newRatioArray.push(item);
    }

    if(newRatioArray.length > 0) {
      newRatioArray.sort((a,b)=> {
        if(a.value > b.value) {
          return 1;
        } else if(a.value < b.value) {
          return -1;
        } else {
          return 0;
        }
      })
    }

    this.chartDataArray = newRatioArray;
  }
}
