import { Component, OnInit, Input } from '@angular/core';
import { Color } from 'ng2-charts';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-department-positive-ratio-years-chart',
  templateUrl: './department-positive-ratio-years-chart.component.html',
  styleUrls: ['./department-positive-ratio-years-chart.component.css']
})
export class DepartmentPositiveRatioYearsChartComponent implements OnInit {

  @Input() ratios:any;

  constructor() { }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{ 
        maxBarThickness: 70,
        scaleLabel: {
          display: true,
          labelString: 'Year'
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          max: 25,
          min: 0,
          stepSize: 10
          
        },
        scaleLabel: {
          display: true,
          labelString: 'Positivity Rate %',
       }
      }]
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let value = Number(tooltipItem.value).toFixed(1);
          return value.toString();
        }
      }
    }
    
  };
 
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartLabels = [];
  public barChartData = [{data: []}];
  public barChartColors: Color[] = [{ backgroundColor: 'rgb(244, 123, 42)' }];

  ngOnInit() {
    if(this.ratios !== null && this.ratios.length > 0 && (this.ratios !== null && this.ratios[0].positiveRateDTO != null)) {
      this.calculateScale();
      this.setBarValues();
    }
  }

  calculateScale() {
    let valueArray = new Array();
    this.ratios.forEach(ratio => {
      if(ratio.positiveRateDTO !== null) {
        valueArray.push(ratio.positiveRateDTO.value);
      }
    })

    let maxValue = valueArray.reduce((a:any, b:any) => Math.max(a, b));
    
    if(maxValue > 25) {
      let maxPeak = Math.ceil((maxValue + 10) / 10) * 10
      this.barChartOptions.scales.yAxes[0].ticks.max = maxPeak;
    }
  }

  setBarValues() {
    if(this.ratios.length > 1) {
      this.ratios.sort((a,b)=> {
        if(a.year > b.year) {
          return 1;
        } else if(a.year < b.year) {
          return -1;
        } else {
          return 0;
        }
      })
    }

    this.ratios.forEach(ratio => {     
      if(ratio.positiveRateDTO.type == "PERCENT" && ratio.positiveRateDTO.value > 0) {
        this.barChartLabels.push(ratio.year);
        this.barChartData[0].data.push(ratio.positiveRateDTO.value);
      }
      
    })
  }

}
