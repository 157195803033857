import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from 'src/app/service/patient.service';
import { Cluster } from 'src/app/models/cluster';
import { PatientEmitter } from 'src/app/shared/emitter/patient-emitter';
import { MatDialog } from '@angular/material/dialog';
import { AddClusterModalComponent } from '../shared/add-cluster-modal/add-cluster-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patient-cluster',
  templateUrl: './patient-cluster.component.html',
  styleUrls: ['./patient-cluster.component.css']
})
export class PatientClusterComponent implements OnInit {

  @ViewChild('updateClusterSuccessMsg', {static: false}) updateClusterSuccessMsg: ElementRef;
  @ViewChild('updateClusterErrorMsg', {static: false}) updateClusterErrorMsg: ElementRef;

  public loading:boolean = false;
  public actualNumber:number;
  public totalElements:number;
  public totalPages:number;
  public actualSize:number;

  public areaId:string;
  public clusters:Cluster[] = [];
  public ratio:number = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private patientEmitter: PatientEmitter,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.areaId = this.activatedRoute.snapshot.paramMap.get("id"); 
    this.patientEmitter.reloadCluster.subscribe(res => this.loadClusters());
  }

  ngOnInit() {
    this.loadClusters();
  }

  loadClusters(size=10, page=0, sort=null, query=null) {
    this.loading = true;
    const params: any = {
      size: size,
      page: page,
      sort: sort,
      areaId: this.areaId
    };

    if(query != null) {
      params.query = query;
    }

    this.patientService.getClusters(params).subscribe((res:any) => {
      this.clusters = res.entry;
      this.setupPaginator(res);
      this.loading = false;
    })
  }

  setupPaginator(res: any) {
    this.actualNumber = res.pagination.number;
    this.totalElements = res.pagination.totalElements;
    this.totalPages = res.pagination.totalPages;
    this.actualSize = res.pagination.size;
  }

  computeRation(index:number) {
    let cluster: Cluster = this.clusters[index];
    if(cluster.her2 != null && cluster.cep17 != null) {
      let ratio = (cluster.her2 / cluster.cep17);
      return ratio;
    } else {
      return null;
    }
  }

  openEditClusterModal(cluster:Cluster) {
    const dialogRef = this.dialog.open(AddClusterModalComponent, {
      width: '500px', data: {cluster: cluster, editMode: true}
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.updateCluster(cluster.id, result);
      }
    });
  }

  updateCluster(id:number, body:any) {
    this.patientService.updateCluster(id, body).subscribe((res:Cluster) => {
      this.loadClusters();
      this.patientEmitter.reloadAreaDetails.next(true);
      this.toastr.success(this.updateClusterSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.updateClusterErrorMsg.nativeElement.textContent);
    })
  }

  paginatorModification(value:any) {
    this.loadClusters(value.size, value.page);
  }

}
