import { Component, OnInit } from '@angular/core';
import { PatientService } from 'src/app/service/patient.service';
import { AuthService } from '../../auth/service/auth.service';
import { Entry } from 'src/app/models/entry';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-entries',
  templateUrl: './entries.component.html',
  styleUrls: ['./entries.component.css']
})
export class EntriesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
