import { Component, OnInit } from '@angular/core';
import { Entry } from 'src/app/models/entry';
import { PatientService } from 'src/app/service/patient.service';
import { AuthService } from '../../auth/service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-other-entries',
  templateUrl: './other-entries.component.html',
  styleUrls: ['./other-entries.component.css']
})
export class OtherEntriesComponent implements OnInit {

  public loading = false;

  public actualNumber:number;
  public totalElements:number;
  public totalPages:number;
  public actualSize:number;

  public entries:Entry[] = [];

  constructor(
    private patientService: PatientService, 
    private authService: AuthService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loadEntries();
  }

  goBack() {
    this.location.back();
  }

  openCreateSingleRecordPage() {
    this.router.navigate(['entries/create/single']);
  }

  paginatorModification(value:any) {
    this.loadEntries(value.size, value.page);
  }

  loadEntries(size=10, page=0, sort="name", query=null) {
    this.loading = true;
    const params: any = {
      size: size,
      page: page,
      sort: sort,
      projection: 'generalEntry',
      ownerId: this.authService.getLoggedUser()
    };

    if(query) {
      params.query = query;
    }

    this.patientService.getOthersEntries(params).subscribe((res:any) => {
      this.entries = res.entry;
      this.setupPaginator(res);
      this.loading = false;
    });
  }

  setupPaginator(res: any) {
    this.actualNumber = res.pagination.number;
    this.totalElements = res.pagination.totalElements;
    this.totalPages = res.pagination.totalPages;
    this.actualSize = res.pagination.size;
  }

  redirectToDetails(entry: Entry) {
    this.router.navigate([entry.id], {relativeTo: this.route});
  }

}
