import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Cluster } from 'src/app/models/cluster';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormUtilComponent } from 'src/app/utils/form-util.component';

@Component({
  selector: 'app-add-cluster-modal',
  templateUrl: './add-cluster-modal.component.html',
  styleUrls: ['./add-cluster-modal.component.css']
})
export class AddClusterModalComponent extends FormUtilComponent implements OnInit {

  public clusterFormGroup: FormGroup;
  public cluster: Cluster;

  public editMode:boolean = false;
  public submitted:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddClusterModalComponent>
    ) {
      super();
      this.setEditValues();
      this.clusterFormGroup = this.formBuilder.group(this.getClusterForm());
    }

  ngOnInit() {
  }

  private setEditValues() {
    if(this.data) {
      this.editMode = this.data.editMode;
      this.cluster = this.data.cluster;
    }
  }

  private getClusterForm() {
    return {
      clusterNumberInputCtrl: [this.editMode ? this.cluster.clusterNumber : null, [Validators.required, Validators.min(1), Validators.max(20)]],
      her2InputCtrl: [this.editMode ? this.cluster.her2 : null, [Validators.required, Validators.min(1), Validators.max(9999)]],
      cep17InputCtrl: [this.editMode ? this.cluster.cep17 : null, [Validators.required, Validators.min(1), Validators.max(9999)]],
    }
  }

  private getClusterFormValues() {
    const patientData = {
      clusterNumber: this.getInputValue(this.clusterFormGroup, 'clusterNumberInputCtrl'),
      her2: this.getInputValue(this.clusterFormGroup, 'her2InputCtrl'),
      cep17: this.getInputValue(this.clusterFormGroup, 'cep17InputCtrl')
    }

    return patientData;
  }

  onSubmit() {
    this.submitted = true;
    
    if (this.clusterFormGroup.valid) {
      this.dialogRef.close(this.getClusterFormValues());
    } else {
      return;
    }
  }

}
