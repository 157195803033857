import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FormUtilComponent } from 'src/app/utils/form-util.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientEmitter } from 'src/app/shared/emitter/patient-emitter';
import { TestIsh } from 'src/app/models/entry';

@Component({
  selector: 'app-add-test-ish-modal',
  templateUrl: './add-test-ish-modal.component.html',
  styleUrls: ['./add-test-ish-modal.component.css']
})
export class AddTestIshModalComponent extends FormUtilComponent implements OnInit {

  public ishFormGroup: FormGroup;
  public editMode:boolean = false;
  public testIsh:TestIsh = null;
  public submitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddTestIshModalComponent>,
    private formBuilder: FormBuilder
    ) {
    super();
    this.setEditValues()
    this.ishFormGroup = this.formBuilder.group(this.getIshForm());
   }

  ngOnInit() {
  }

  private getIshForm() {
    return {
      sampleNumberInputCtrl: [this.editMode ? this.testIsh.sampleNumber : '', [Validators.required, Validators.min(1), Validators.max(999999999)]],
      blockNumberInputCtrl: [this.editMode ? this.testIsh.blockNumber : '', [Validators.required, Validators.min(1), Validators.max(999999999)]],
    }
  }

  getIshFormValue() {
    const patientData = {
      sampleNumber: this.getInputValue(this.ishFormGroup, 'sampleNumberInputCtrl'),
      blockNumber: this.getInputValue(this.ishFormGroup, 'blockNumberInputCtrl'),
    }

    return patientData;
  }

  private setEditValues() {
    if(this.data) {
      this.editMode = this.data.editMode;
      this.testIsh = this.data.testIsh;
    }
  }

  get controls() { return this.ishFormGroup.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.ishFormGroup.valid) {
      this.dialogRef.close(this.getIshFormValue());
    } else {
      return;
    }
  }

}
