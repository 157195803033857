import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable()
export class LoggedGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if(this.authService.isAuthenticated()) {
        if(this.authService.isUserAppAdmin()) {
            this.router.navigate(["admin/dashboard"]);
        } else {
            this.router.navigate(["dashboard"]);
        }

        return false;
    }

    return true;
  }
}
