import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from './auth.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
  })
  export class AuthInterceptorService implements HttpInterceptor {

    constructor(
      private authService: AuthService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
      let token = sessionStorage.getItem('token');
        if(sessionStorage.getItem('username') && token && this.authService.isAuthenticated()) {
            req = req.clone({
                setHeaders: {
                    Authorization: token
                }
            })
        }

        return next.handle(req).pipe(catchError((err) => {
            if (err.status === 401) {
              this.authService.logOut();
              this.router.navigate(['/login']);
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
          })
        );
    }
  }
