import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-interval-end',
  templateUrl: './time-interval-end.component.html',
  styleUrls: ['./time-interval-end.component.css']
})
export class TimeIntervalEndComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
