import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/models/patient';
import { AuthService } from '../../auth/service/auth.service';
import { PatientService } from 'src/app/service/patient.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { EditPatientPrimaryModalComponent } from '../shared/edit-patient-primary-modal/edit-patient-primary-modal.component';
import { EditPatientTumorModalComponent } from '../shared/edit-patient-tumor-modal/edit-patient-tumor-modal.component';
import { GenderType } from 'src/app/utils/enums/gender-type';
import { TopographyType } from 'src/app/utils/enums/topography-type';
import { MorphologyType } from 'src/app/utils/enums/morphology-type';
import { TumorStageType } from 'src/app/utils/enums/tumor-stage-type';
import { AddTestIhcModalComponent } from '../shared/add-test-ihc-modal/add-test-ihc-modal.component';
import { PatientEmitter } from 'src/app/shared/emitter/patient-emitter';
import { AddTestIshModalComponent } from '../shared/add-test-ish-modal/add-test-ish-modal.component';
import { TypesService } from 'src/app/service/types.service';
import { Type } from 'src/app/models/type';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {

  @ViewChild('updatePatientSuccessMsg', {static: false}) updatePatientSuccessMsg: ElementRef;
  @ViewChild('updatePatientErrorMsg', {static: false}) updatePatientErrorMsg: ElementRef;
  @ViewChild('updateTumorSuccessMsg', {static: false}) updateTumorSuccessMsg: ElementRef;
  @ViewChild('updateTumorErrorMsg', {static: false}) updateTumorErrorMsg: ElementRef;
  @ViewChild('updateIhcSuccessMsg', {static: false}) updateIhcSuccessMsg: ElementRef;
  @ViewChild('updateIhcErrorMsg', {static: false}) updateIhcErrorMsg: ElementRef;
  @ViewChild('updateIshSuccessMsg', {static: false}) updateIshSuccessMsg: ElementRef;
  @ViewChild('updateIshErrorMsg', {static: false}) updateIshErrorMsg: ElementRef;

  // public genderTypes = GenderType;
  // public topographyTypes = TopographyType;
  // public morphologyTypes = MorphologyType;
  // public tumorStageTypes = TumorStageType;

  public loading = false;

  private patientId = null; //TODO to remove

  private bulkEntryId:string;
  private patient:Patient;

  private entry:any = null;
  private info:any = null;
  private morphologyTypes:Type[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private authService: AuthService,
    private patientService: PatientService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private typesService: TypesService,
    private patientEmitter: PatientEmitter,
    private router: Router) {
    this.bulkEntryId = this.activatedRoute.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    // this.getEntry();
    this.loadEntry();
  }

  goBack() {
    this.location.back();
  }

  getEntry() {
    this.loading = true;
    this.patientService.CurrentUserEntriesScope.subscribe((res:any[]) => {
      let entries = res;
      if(entries !== null && entries.length > 0) {
        this.entry = entries.find(entry => entry.bulkEntryId == this.bulkEntryId);
        this.info = this.entry.info;
        this.loading = false;
      } else {
        this.loadEntry();
      }
    })
  }

  private loadEntry() {
    const params: any = {
      bulkEntryId: this.bulkEntryId
    };
    this.loading = true;
    this.patientService.getEntriesByBulkEntryId(params).subscribe((res:any) => {
      if(res !== null) {
        this.entry = res[0];
        this.info = this.entry.info;
      }
      this.loading = false;
    })
  }

  openEditPrimaryInfoModal() {
    const dialogRef = this.dialog.open(EditPatientPrimaryModalComponent, {
      width: '500px', data: {patient: this.patient}
    });
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.updatePrimaryPatientEntry(result);
      }
    });
  }

  openEditTumorInfoModal() {
    const dialogRef = this.dialog.open(EditPatientTumorModalComponent, {
      width: '500px', data: {patient: this.patient}
    });
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.updateTumorPatientEntry(result);
      }
    });
  }

  openAddTestIhcModal() {
    const dialogRef = this.dialog.open(AddTestIhcModalComponent, {
      width: '500px', data: {editMode: false, testIhc: null}
    });
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.addIhcTest(result);
      }
    });
  }

  openAddTestIshModal() {
    const dialogRef = this.dialog.open(AddTestIshModalComponent, {
      width: '500px', data: {testIsh: null, editMode: false}
    });
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.addIshTest(result);
      }
    });
  }

  private updatePrimaryPatientEntry(body: any) {
    body.updateDate = new Date();
    this.patientService.updatePatient(this.patientId, body).subscribe(res => {
      // this.loadPatientEntry();
      this.toastr.success(this.updatePatientSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.updatePatientErrorMsg.nativeElement.textContent);
    })
  }

  private updateTumorPatientEntry(body: any) {
    body.updateDate = new Date();
    this.patientService.updatePatient(this.patientId, body).subscribe(res => {
      // this.loadPatientEntry();
      this.toastr.success(this.updateTumorSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.updateTumorErrorMsg.nativeElement.textContent);
    })
  }

  private addIhcTest(body: any) {
    body.patient = "patients/" + this.patientId;
    this.patientService.createIhc(body).subscribe(res => {
      this.patientEmitter.reloadIhc.next(true);
      this.toastr.success(this.updateIhcSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.updateIhcErrorMsg.nativeElement.textContent);
    })
  }

  private addIshTest(body: any) {
    body.patient = "patients/" + this.patientId;
    body.createdByUser = "users/" + this.authService.getLoggedUser();
    this.patientService.createIsh(body).subscribe(res => {
      this.patientEmitter.reloadIsh.next(true);
      this.toastr.success(this.updateIshSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.updateIshErrorMsg.nativeElement.textContent);
    })
  }

  updateEntry() {
    this.router.navigate(['entries/' + this.bulkEntryId +'/edit']);
  }

}
