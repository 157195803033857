import { Component, OnInit, Input } from '@angular/core';
import { GenderRatio } from 'src/app/models/gender-ratio';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-gender-positive-ratio-chart',
  templateUrl: './gender-positive-ratio-chart.component.html',
  styleUrls: ['./gender-positive-ratio-chart.component.css']
})
export class GenderPositiveRatioChartComponent implements OnInit {

  @Input() ratios:GenderRatio;

  constructor() {}

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {position: 'top'},
    scales: {
      xAxes: [{
          maxBarThickness: 70,
          scaleLabel: {
            display: false,
            labelString: 'Gender'
         }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 0,
          stepSize: 10,
        },
        scaleLabel: {
          display: true,
          labelString: 'Positivity Rate %'
       }
      }]
    }
  };
  public barChartLabels: Label[] = ['Gender',];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartColors: Color[] = [{ backgroundColor: '#fd809b' }, { backgroundColor: '#0066cc' }]
  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Female' },
    { data: [], label: 'Male' }
  ];

  ngOnInit() {
    if(this.ratios) {
      this.setBarValues();
    }
  }

  setBarValues() {
    this.barChartData[0].data.push(this.ratios.femalePositiveRatioNumber);
    this.barChartData[1].data.push(this.ratios.malePositiveRatioNumber);
  }

}
