import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './app-paginator.component.html',
  styleUrls: ['./app-paginator.component.css']
})
export class AppPaginatorComponent implements OnInit {

  @Input()
  paginationObjects:any[];
  @Input()
  totalElements:number;
  @Input()
  totalPages:number;
  @Input()
  actualSize:number;
  @Input()
  actualNumber:number;

  @Output() paginator = new EventEmitter<any>();

  public selectedPageSize:number;
  public selectedPageIndex:number;

  public paginatorPageLength:number = 5;
  public paginatorMap = {};

  constructor() { }

  ngOnInit() {
    this.selectedPageSize = this.actualSize !== 0 ? this.actualSize : 10;
    this.selectedPageIndex = this.actualNumber !== null ? this.actualNumber : 0;
    this.calculatePaginatorIndex(this.selectedPageIndex);
  }

  changePageSize(value:number) {
    this.selectedPageSize = value;

    let newPagination = this.setupPaginationValues(0, value);
    this.paginatorChange(newPagination);
  }

  setupPaginationValues(newPage:number, newSize:number) {
    const paginationValues = {
      page: newPage,
      size: newSize
    }

    return paginationValues;
  }

  paginatorChange(value: any) {
    this.paginator.emit(value);
  }

  onPreview() {
    let isPossible = this.previewAccessibility();
    if(isPossible) {
      this.selectedPageIndex = this.selectedPageIndex - 1;
      let newPagination = this.setupPaginationValues(this.selectedPageIndex, this.selectedPageSize);
      this.paginatorChange(newPagination);
    }
  }

  onNext() {
    let isPossible = this.nextAccessibility();
    if(isPossible) {
      this.selectedPageIndex = this.selectedPageIndex + 1;
      let newPagination = this.setupPaginationValues(this.selectedPageIndex, this.selectedPageSize);
      this.paginatorChange(newPagination);
    }
  }

  onFirst() {
    let isPossible = this.previewAccessibility();
    if(isPossible) {
      this.selectedPageIndex = 0;
      let newPagination = this.setupPaginationValues(this.selectedPageIndex, this.selectedPageSize);
      this.paginatorChange(newPagination);
    }
  }

  onLast() {
    let isPossible = this.nextAccessibility();
    if(isPossible) {
      this.selectedPageIndex = (this.totalPages - 1)
      let newPagination = this.setupPaginationValues(this.selectedPageIndex, this.selectedPageSize);
      this.paginatorChange(newPagination);
    }
  }

  onPageChange(indexValue:number) {
    let newPagination = this.setupPaginationValues(indexValue, this.selectedPageSize);
    this.paginatorChange(newPagination);
  }

  calculatePaginatorIndex(index:number) {
    if(this.totalPages <= this.paginatorPageLength) {
      this.createPaginatorMap(0, this.totalPages-1)
    } else {
      this.setPaginatorIndexCases(index);
    }
  }

  setPaginatorIndexCases(index:number) {
    let optionIndex = index + 1;

    if(optionIndex < 4) {
      this.createPaginatorMap(0 , this.paginatorPageLength - 1);
    } else if(optionIndex > this.totalPages - 3) {
      this.createPaginatorMap(this.totalPages - this.paginatorPageLength , this.totalPages - 1);
    } else {
      this.createPaginatorMap(index - 2 , index + 2);
    }
  }

  createPaginatorMap(startIndex:number, endIndex:number) {
    for(var i=startIndex; i<=endIndex; i++) {
      this.paginatorMap[i] = i+1;
    }
  }

  previewAccessibility(): boolean {
    if(this.totalPages === 1) {
      return false;
    } else {
      if(this.actualNumber === 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  nextAccessibility(): boolean {
    if(this.totalPages === 1) {
      return false;
    } else {
      if(this.actualNumber === (this.totalPages - 1)) {
        return false;
      } else {
        return true;
      }
    }
  }




}
