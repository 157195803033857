import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';

export function TimeFrameEndsValidator(timeIntervalStart:string, timeIntervalEnd:string, timeFrameTypeFlag:string, flag222:string ) {
    return (formGroup: FormGroup) => {
        
        let flag:boolean = Boolean(timeFrameTypeFlag);
        
        if(flag) {
            let start = formGroup.controls[timeIntervalStart].value;
            let end = formGroup.controls[timeIntervalEnd].value;

            if(start == null || end == null) {
                formGroup.controls[timeIntervalStart].setErrors({required: true});
                formGroup.controls[timeIntervalEnd].setErrors({required: true});
                return;
            } else {
                formGroup.controls[timeIntervalStart].setErrors(null);
                formGroup.controls[timeIntervalEnd].setErrors(null);
            }

            let momentStartValue:Moment = moment(start, 'YYYY/MM/DD');
            let momentEndValue:Moment = moment(end, 'YYYY/MM/DD');

            // let isAfter = momentEndValue.isAfter(momentStartValue);
            let isAfter = momentEndValue.isSameOrAfter(momentStartValue)

            if(!isAfter) {
                formGroup.controls[timeIntervalStart].setErrors({endIsBefore: true});
                formGroup.controls[timeIntervalEnd].setErrors({endIsBefore: true});
                return;
            } else {
                formGroup.controls[timeIntervalStart].setErrors(null);
                formGroup.controls[timeIntervalEnd].setErrors(null);
            }
        }

        if(!flag) {
            formGroup.controls[timeIntervalStart].setErrors(null);
            formGroup.controls[timeIntervalEnd].setErrors(null);
        }
    }
}