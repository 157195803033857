import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormUtilComponent } from 'src/app/utils/form-util.component';
import { Patient } from 'src/app/models/patient';
import { GenderType } from 'src/app/utils/enums/gender-type';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {Moment} from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-edit-patient-primary-modal',
  templateUrl: './edit-patient-primary-modal.component.html',
  styleUrls: ['./edit-patient-primary-modal.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class EditPatientPrimaryModalComponent extends FormUtilComponent implements OnInit {

  public editPrimaryInfoFormGroup: FormGroup
  public patient:Patient;
  public submitted = false;

  public genderTypes = GenderType;
  public genderTypesKeys:string[] = [];
  public filteredGenderTypes: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPatientPrimaryModalComponent>,
    private formBuilder: FormBuilder
  ) 
  {
    super();
    this.patient = this.data.patient;
    this.genderTypesKeys = Object.keys(this.genderTypes);
    this.editPrimaryInfoFormGroup = this.formBuilder.group(this.getPrimaryInfoForm());
  }

  ngOnInit() {
    this.filteredGenderTypes = this.editPrimaryInfoFormGroup.get('genderInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.genderTypesKeys, value)));
  }

  private getPrimaryInfoForm() {
    return {
      bornDateInputCtrl: [this.patient.bornDate != null ? moment().year(parseInt(this.patient.bornDate)) : null],
      genderInputCtrl: [this.genderTypes[this.patient.gender], [Validators.required]]
    }
  }

  private typeFilter(filterCase:string[], value: string): string[] {
    const filterValue = this.normalizeFilteredValue(value);
    return filterCase.filter(type => this.normalizeFilteredValue(type).includes(filterValue));
  }

  private normalizeFilteredValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
  
  getAdminLoginFormValue() {
    let bornYear = null;
    let bd = this.getInputValue(this.editPrimaryInfoFormGroup, 'bornDateInputCtrl');
    if(bd !== null) {
      bornYear = bd.year();
    }

    const patientEditionData = {
      bornDate: bornYear,
      gender: this.getInputValue(this.editPrimaryInfoFormGroup, 'genderInputCtrl')
    }

    if(patientEditionData.gender) {
      let genderValue = Object.keys(this.genderTypes).filter(x => this.genderTypes[x] === patientEditionData.gender);
      patientEditionData.gender = genderValue[0];
    }

    return patientEditionData;
  }

  get controls() { return this.editPrimaryInfoFormGroup.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.editPrimaryInfoFormGroup.valid) {
      this.dialogRef.close(this.getAdminLoginFormValue());
    } else {
      return;
    }
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    this.editPrimaryInfoFormGroup.get('bornDateInputCtrl').setValue(ctrlValue);
    datepicker.close();
  }

}
