import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { IhcIshInstitutionRatio } from '../models/ihc-ish-institution-ratio';
import { GenderRatio } from '../models/gender-ratio';
import { YearCount } from '../models/year-count';

@Injectable({
    providedIn: 'root'
  })
export class ReportService {

  private apiUrl: string;
  private headers = { 'Content-Type': 'application/json'};

  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.apiBaseUrl;
  }

  getDepartmentsPositiveRateWholeRange(params:any) {
    return this.httpClient
      .get(this.apiUrl + "statistic/get/departmentStatistics", { headers: this.headers, params: params })
      .pipe(map((res: any) => { return res as any }))
  }

  getDepartmentPositiveRateInAllYears() {
    return this.httpClient
      .get(this.apiUrl + "statistic/get/departmentStatisticsInYears", { headers: this.headers })
      .pipe(map((res: any) => { return res as any }))
  }

  getUserInstitutionPositiveRate() {
    return this.httpClient
      .get(this.apiUrl + "statistic/get/getUserInstitutionPositiveRate", { headers: this.headers })
      .pipe(map((res: any) => { return res as any }))
  }

  getUserInstitutionStatistics() {
    return this.httpClient
      .get(this.apiUrl + "statistic/get/getUserInstitutionStatistics", { headers: this.headers })
      .pipe(map((res: any) => { return res as any }))
  }

  getUserLastEntriesStatistics() {
    return this.httpClient
      .get(this.apiUrl + "statistic/get/getUserLastEntriesStatistics", { headers: this.headers })
      .pipe(map((res: any) => { return res as any }))
  }

  getUserCountryStatistics() {
    return this.httpClient
      .get(this.apiUrl + "statistic/get/getUserCountryStatistics", { headers: this.headers })
      .pipe(map((res: any) => { return res as any }))
  }

  filterPositiveRatioChart(params:any) {
    return this.httpClient
      .get(this.apiUrl + "statistic/filter/positiveRatioChart", { headers: this.headers, params: params })
      .pipe(map((res: any) => { return res as any }))
  }
  filterPositiveRatioPerYearChart(params:any) {
    return this.httpClient
      .get(this.apiUrl + "statistic/filter/positiveRatioPerYearChart", { headers: this.headers, params: params })
      .pipe(map((res: any) => { return res as any }))
  }





  getInstitutionWithTestsRatio() {
    return this.httpClient
      .get(this.apiUrl + "report/institutiontestsratio", { headers: this.headers })
      .pipe(map((res: any) => { return res as IhcIshInstitutionRatio }))
  }

  getGenderRatio() {
    return this.httpClient
      .get(this.apiUrl + "report/genderRatio", { headers: this.headers })
      .pipe(map((res: any) => { return res as GenderRatio }))
  }

  getYearCount() {
    return this.httpClient
      .get(this.apiUrl + "report/yearCount", { headers: this.headers })
      .pipe(map((res: any) => { return res as YearCount[] }))
  }


}