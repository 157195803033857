import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
  })
  export class ExceptionInterceptorService implements HttpInterceptor {

    constructor(private toasterService: ToastrService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
          catchError(err => {
            let message:string = null;

            if(err instanceof HttpErrorResponse) {
              message = this.getErrorByCode(err.error.errorCode)
            }

            if(message !== null) {
              this.toasterService.error(message)
            } else {
              return throwError(err);
            }
          })
        );
    }

    getErrorByCode(errorCode: string): string {
      switch(errorCode) {
        case "change_password_token_used": return "Reset link already used";
        case "change_password_token_expired": return "Reset link expired";
        case "change_password_token_not_exist": return "Wrong owner of this request or request url is broken";
        case "change_password_token_not_match": return "Request token not match to existing token";
        case "unknown_error": return "Unknown error has occurred. Your request could not be processed";

        default: return null;
      }
    }


  }
