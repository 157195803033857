import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';
import { YearCount } from 'src/app/models/year-count';
import { element } from 'protractor';

@Component({
  selector: 'app-birth-year-chart',
  templateUrl: './birth-year-chart.component.html',
  styleUrls: ['./birth-year-chart.component.css']
})
export class BirthYearChartComponent implements OnInit {

  @Input() ratios:YearCount[];

  public bubbleChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            min: 1930,
            max: 2030,
          },
          scaleLabel: {
            display: true,
            labelString: 'Birth year'
         }
        }
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 1000,
          },
          scaleLabel: {
            display: true,
            labelString: 'Number of patients'
         }
        }
      ]
    }
  };
  public bubbleChartType: ChartType = 'bubble';
  public bubbleChartLegend = false;

  public bubbleChartData = [
    {
      data: [],
      label: 'Year',
      backgroundColor: 'green',
      borderColor: 'blue',
      hoverBackgroundColor: 'purple',
      hoverBorderColor: 'red',
    },
  ];

  public bubbleChartColors: Color[] = [
    {
      backgroundColor: ['red', 'green', 'blue','purple','yellow','brown','magenta','cyan','orange','pink','#E6E6FA','#D8BFD8','#DDA0DD','#EE82EE','#DA70D6','#FF00FF',
        '#BA55D3','#9370DB','#8A2BE2','#9400D3','#9932CC','#8B008B','#800080','#4B0082', '#F0F8FF', '#FAEBD7', '#00FFFF', '#A52A2A', '#7FFF00', '#8A2BE2', '#7FFFD4'
      ]
    }
  ];

  constructor() { }

  ngOnInit() {
    if(this.ratios) {
      this.setCharValues();
    }
  }

  setCharValues() {
    let yearsCountArray:number[] = [];
    this.ratios.forEach(element => yearsCountArray.push(element.yearCount));
    let maxValue = Math.max.apply(null, yearsCountArray);
    this.bubbleChartOptions.scales.yAxes[0].ticks.max = Math.ceil(maxValue/100)*100;

    this.ratios.forEach(ratio => {
      this.bubbleChartData[0].data.push({ x: ratio.year, y: ratio.yearCount, r: ratio.yearCount });
    })
  }

}
