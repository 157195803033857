import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormUtilComponent } from 'src/app/utils/form-util.component';
import { GenderType } from 'src/app/utils/enums/gender-type';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TopographyType } from 'src/app/utils/enums/topography-type';
import { MorphologyType } from 'src/app/utils/enums/morphology-type';
import { TumorStageType } from 'src/app/utils/enums/tumor-stage-type';
import { ScoreType } from 'src/app/utils/enums/score-type';
import { IshDoneType } from 'src/app/utils/enums/ish-done-type';
import { IshPositivieType } from 'src/app/utils/enums/ish-positive-type';
import { AuthService } from '../../auth/service/auth.service';
import { PatientService } from 'src/app/service/patient.service';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateAdapterUtilComponent, APP_DATE_FORMATS } from 'src/app/utils/date-adapter-util/date-adapter-util.component';
import { MatDatepicker } from '@angular/material/datepicker';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {Moment} from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


@Component({
  selector: 'app-add-single-patient',
  templateUrl: './add-single-patient.component.html',
  styleUrls: ['./add-single-patient.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class AddSinglePatientComponent extends FormUtilComponent implements OnInit {

  @ViewChild('createEntrySuccessMsg', {static: false}) createEntrySuccessMsg: ElementRef;
  @ViewChild('createEntryErrorMsg', {static: false}) createEntryErrorMsg: ElementRef;

  public advancedPatientInfoFormGroup: FormGroup;
  public submitted:boolean = false;

  public scoreTypes = ScoreType;
  public scoreKeys:string[] = [];
  public filteredScoreTypes: Observable<string[]>;

  public ishDoneTypes = IshDoneType;
  public ishDoneKeys:string[] = [];
  public filteredIshDoneTypes: Observable<string[]>;

  public ishPositiveTypes = IshPositivieType;
  public ishPositiveKeys:string[] = [];
  public filteredIshPositiveTypes: Observable<string[]>;

  public genderTypes = GenderType;
  public genderTypesKeys:string[] = [];
  public filteredGenderTypes: Observable<string[]>;

  public topographyTypes = TopographyType;
  public topographyKeys:string[] = [];
  public filteredTopographyTypes: Observable<string[]>;

  public morphologyTypes = MorphologyType;
  public morphologyKeys:string[] = [];
  public filteredMorphologyTypes: Observable<string[]>;

  public tumorStageTypes = TumorStageType;
  public tumorStageKeys:string[] = [];
  public filteredTumorStageTypes: Observable<string[]>;
  

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private patientService: PatientService,
    private toastr: ToastrService) { 
      super();
      this.advancedPatientInfoFormGroup = this.formBuilder.group(this.getAdvancedPatientInfoForm());

      this.scoreKeys = Object.keys(this.scoreTypes);
      this.ishDoneKeys = Object.keys(this.ishDoneTypes);
      this.ishPositiveKeys = Object.keys(this.ishPositiveTypes);
      this.genderTypesKeys = Object.keys(this.genderTypes);
      this.topographyKeys = Object.keys(this.topographyTypes);
      this.morphologyKeys = Object.keys(this.morphologyTypes);
      this.tumorStageKeys = Object.keys(this.tumorStageTypes);
    }

  ngOnInit() {
    this.filteredScoreTypes = this.advancedPatientInfoFormGroup.get('scoreInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.scoreKeys, value)));
    this.filteredIshDoneTypes = this.advancedPatientInfoFormGroup.get('ishDoneInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.ishDoneKeys, value)));
    this.filteredIshPositiveTypes = this.advancedPatientInfoFormGroup.get('ishPositiveInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.ishPositiveKeys, value)));
    this.filteredGenderTypes = this.advancedPatientInfoFormGroup.get('genderInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.genderTypesKeys, value)));
    this.filteredTopographyTypes = this.advancedPatientInfoFormGroup.get('topographyInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.topographyKeys, value)));
    this.filteredMorphologyTypes = this.advancedPatientInfoFormGroup.get('morphologyInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.morphologyKeys, value)));
    this.filteredTumorStageTypes = this.advancedPatientInfoFormGroup.get('tumorStageInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.tumorStageKeys, value)));
  }

  goBack() {
    this.location.back();
  }

  savePatientInfo() {
    let patient:any = this.getAdminLoginFormValue();
    let userId = this.authService.getLoggedUser();
    patient.carriedOutById = userId;

    this.patientService.createPatient(patient).subscribe(res => {
      this.toastr.success(this.createEntrySuccessMsg.nativeElement.textContent);
      this.goBack();
    }, err => {
      this.toastr.error(this.createEntryErrorMsg.nativeElement.textContent);
    })
  }

  private getAdvancedPatientInfoForm() {
    return {
      scoreInputCtrl: [null, [Validators.required]],
      ishDoneInputCtrl: [null, [Validators.required]],
      ishPositiveInputCtrl: [null, [Validators.required]],
      genderInputCtrl: ['F'],
      bornDateInputCtrl: [null], //moment(null).format('YYYY')
      sampleNumberInputCtrl: ['', [Validators.min(1), Validators.max(999999999)]],
      blockNumberInputCtrl: ['', [Validators.min(1), Validators.max(999999999)]],
      topographyInputCtrl: [null],
      morphologyInputCtrl: [null],
      topographicLocalisationInputCtrl: [''],
      notesInputCtrl: [''],
      tumorStageInputCtrl: [null, [Validators.required]]
    }
  }

  private getAdminLoginFormValue() {
    const patientData = {
      score: this.getInputValue(this.advancedPatientInfoFormGroup, 'scoreInputCtrl'),
      ishDone: this.getInputValue(this.advancedPatientInfoFormGroup, 'ishDoneInputCtrl'),
      ishPositive: this.getInputValue(this.advancedPatientInfoFormGroup, 'ishPositiveInputCtrl'),
      gender: this.getInputValue(this.advancedPatientInfoFormGroup, 'genderInputCtrl'),
      bornDate: this.getInputValue(this.advancedPatientInfoFormGroup, 'bornDateInputCtrl'),
      sampleNumber: this.getInputValue(this.advancedPatientInfoFormGroup, 'sampleNumberInputCtrl'),
      blockNumber: this.getInputValue(this.advancedPatientInfoFormGroup, 'blockNumberInputCtrl'),
      topography: this.getInputValue(this.advancedPatientInfoFormGroup, 'topographyInputCtrl'),
      morphology: this.getInputValue(this.advancedPatientInfoFormGroup, 'morphologyInputCtrl'),
      topographicLocalisation: this.getInputValue(this.advancedPatientInfoFormGroup, 'topographicLocalisationInputCtrl'),
      notes: this.getInputValue(this.advancedPatientInfoFormGroup, 'notesInputCtrl'),
      tumorStage: this.getInputValue(this.advancedPatientInfoFormGroup, 'tumorStageInputCtrl')
    }
    
    if(patientData.bornDate) {
      patientData.bornDate = patientData.bornDate.year();
    }

    if(patientData.score) {
      let scoreValue = Object.keys(this.scoreTypes).filter(x => this.scoreTypes[x] === patientData.score);
      patientData.score = scoreValue[0];
    }
    if(patientData.ishDone) {
      let ishDoneValue = Object.keys(this.ishDoneTypes).filter(x => this.ishDoneTypes[x] === patientData.ishDone);
      patientData.ishDone = ishDoneValue[0];
    }
    if(patientData.ishPositive){
      let ishPositiveValue = Object.keys(this.ishPositiveTypes).filter(x => this.ishPositiveTypes[x] === patientData.ishPositive);
      patientData.ishPositive = ishPositiveValue[0];
    }
    if(patientData.gender) {
      let genderValue = Object.keys(this.genderTypes).filter(x => this.genderTypes[x] === patientData.gender);
      patientData.gender = genderValue[0];
    }
    if(patientData.topography) {
      let topographyValue = Object.keys(this.topographyTypes).filter(x => this.topographyTypes[x] === patientData.topography);
      patientData.topography = topographyValue[0];
    }
    if(patientData.morphology) {
      let morphologyValue = Object.keys(this.morphologyTypes).filter(x => this.morphologyTypes[x] === patientData.morphology);
      patientData.morphology = morphologyValue[0];
    }
    if(patientData.tumorStage) {
      let tumorStageValue = Object.keys(this.tumorStageTypes).filter(x => this.tumorStageTypes[x] === patientData.tumorStage);
      patientData.tumorStage = tumorStageValue[0];
    }
    
    return patientData;
  }

  private typeFilter(filterCase:string[], value: string): string[] {
    const filterValue = this.normalizeFilteredValue(value);
    return filterCase.filter(type => this.normalizeFilteredValue(type).includes(filterValue));
  }

  private normalizeFilteredValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  } 
  
  get controls() { return this.advancedPatientInfoFormGroup.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.advancedPatientInfoFormGroup.valid) {
      this.savePatientInfo();
    } else {
      return;
    }
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    this.advancedPatientInfoFormGroup.get('bornDateInputCtrl').setValue(ctrlValue);
    datepicker.close();
  }

}
