export enum MorphologyType {
    ADENOCARCINOMAXMUCINOUSM84803 = 'Adenocarcinoma, mucinous M84803',
    ADENOCARCINOMAXNOSM81403 = 'Adenocarcinoma, NOS M81403',
    APOCRINEDCISM85732 = 'Apocrine DCIS M85732',
    CARCINOMAADENOIDCYSTICM82003 = 'Carcinoma adenoid cystic M82003',
    CARCINOMAADENOSQUAMOUSM85603 = 'Carcinoma adenosquamous M85603',
    CARCINOMAAPOCRINEM85733 = 'Carcinoma apocrine M85733',
    CARCINOMACLEARCELLM83103 = 'Carcinoma clear cell  M83103',
    CARCINOMACRIBRIFORMM83013 = 'Carcinoma cribriform M83013',
    CARCINOMADUCTALINSITUNOSM85002 = 'Carcinoma ductal in situ NOS M85002',
    CARCINOMAINFILTRATINGDUCTALXNSTM85003 = 'Carcinoma infiltrating ductal/NST M85003',
    CARCINOMAINFILTRATINGLOBULARM85203 = 'Carcinoma infiltrating lobular M85203',
    CARCINOMAINFLAMMATORYM85303 = 'Carcinoma inflammatory M85303',
    CARCINOMAINVASIVEMICROPAPILLARYM85033 = 'Carcinoma invasive micropapillary M85033',
    CARCINOMALOBULARINSITUM85202 = 'Carcinoma lobular in situ M85202',
    CARCINOMAMEDULLARYM85103 = 'Carcinoma medullary M85103',
    CARCINOMAMETAPLASTICM80333 = 'Carcinoma metaplastic M80333',
    CARCINOMAMETASTATICM80106 = 'Carcinoma metastatic M80106',
    CARCINOMAMICROINVASIVEM80715 = 'Carcinoma microinvasive M80715',
    CARCINOMAMIXEDXSUBTYPINBEMERKUNGENAUFZXHLENX = 'Carcinoma mixed (Subtyp in Bemerkungen aufzählen)',
    CARCINOMAMUCINOUSM84803 = 'Carcinoma mucinous M84803',
    CARCINOMAMUCOEPIDERMOIDM84303 = 'Carcinoma mucoepidermoid M84303',
    CARCINOMAMYOEPITHELIALM85623 = 'Carcinoma myoepithelial M85623',
    CARCINOMANEUROENDOCRINEM82403 = 'Carcinoma neuroendocrine M82403',
    CARCINOMAPAPILLARYINSITUXENCYSTEDM82602 = 'Carcinoma papillary in situ/encysted M82602',
    CARCINOMAPAPILLARYINVASIVEM82603 = 'Carcinoma papillary invasive M82603',
    CARCINOMASECRETORYM85023 = 'Carcinoma secretory M85023',
    CARCINOMASIGNETRINGM84903 = 'Carcinoma signet ring M84903',
    CARCINOMASPINDLECELLM80323 = 'Carcinoma spindle cell M80323',
    CARCINOMATUBULARM82113 = 'Carcinoma tubular M82113',
    CARCINOMATUBULARMIXEDM85213 = 'Carcinoma tubular mixed M85213',
    CARCINOMAUNDIFFERENTIATEDM80203 = 'Carcinoma undifferentiated M80203',
    CARCINOMAXSIGNETRINGCELLM84903 = 'Carcinoma, signet ring cell M84903',
    CARCINOMAXSQUAMOUSCELLXNOSM80703 = 'Carcinoma, squamous cell, NOS M80703',
    NEUROENDOCRINEDCISM82402 = 'Neuroendocrine DCIS M82402',
    NOT_KNOWN = 'Not known'
}