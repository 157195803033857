import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Area } from 'src/app/models/area';
import { FormUtilComponent } from 'src/app/utils/form-util.component';

@Component({
  selector: 'app-add-area-modal',
  templateUrl: './add-area-modal.component.html',
  styleUrls: ['./add-area-modal.component.css']
})
export class AddAreaModalComponent extends FormUtilComponent implements OnInit {

  public areaFormGroup: FormGroup;
  public area: Area;

  public editMode:boolean = false;
  public submitted:boolean = false;
  public areaDefaultValues = [1, 2, 3];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddAreaModalComponent>
  ) {
    super();
    this.setEditValues();
    this.areaFormGroup = this.formBuilder.group(this.getAreaForm()); 
  }

  ngOnInit() {
  }

  private setEditValues() {
    if(this.data) {
      this.editMode = this.data.editMode;
      this.area = this.data.area;
    }
  }

  private getAreaForm() {
    return {
      areaNumberInputCtrl: [this.editMode ? this.area.areaNumber : null, [Validators.required, Validators.min(1), Validators.max(3)]]
    }
  }

  private getAreaFormValues() {
    const patientData = {
      areaNumber: this.getInputValue(this.areaFormGroup, 'areaNumberInputCtrl')
    }

    return patientData;
  }

  onSubmit() {
    this.submitted = true;

    if (this.areaFormGroup.valid) {
      this.dialogRef.close(this.getAreaFormValues());
    } else {
      return;
    }
  }

}
