import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from 'src/app/service/report.service';
import { MatDialog } from '@angular/material/dialog';
import { InformationModalComponent } from '../shared/information-modal/information-modal.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  public loading:boolean = false;
  public institutionRate:any = null;
  public institutionIntervals:any = null;
  public countryRate:any = null;
  public statisticIndicator:any = null;

  public depName:String = null;
  public instName:String = null;

  constructor(
    private router: Router,
    private reportService: ReportService,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
    this.loadLastUserEntryStatistic();
    this.loadUserCountryStatistics();
  }

  openPatientPage() {
    this.router.navigate(['entries']);
  }

  openCreateSingleRecordPage() {
    this.router.navigate(['entries/create/single']);
  }

  openCreateMultipleRecordPage() {
    this.router.navigate(['entries/create/multiple']);
  }

  openReportPage() {
    this.router.navigate(['reports']);
  }

  // loadInstitutionStatistics() {
  //   this.loading = true;
  //   this.reportService.getUserInstitutionStatistics().subscribe((res: any) => {
  //     this.institutionRate = res.positiveRate;
  //     this.institutionIntervals = res.confidenceInterval;
  //     this.loading = false;
  //   })
  // }

  loadLastUserEntryStatistic() {
    this.loading = true;
    this.reportService.getUserLastEntriesStatistics().subscribe((res: any) => {
      this.institutionRate = res.positiveRate;
      this.institutionIntervals = res.confidenceInterval;
      this.statisticIndicator = res.statisticIndicator;
      
      this.depName = res.departmentName;
      this.instName = res.institutionName;
      this.loading = false;
    })
  }

  loadUserCountryStatistics() {
    this.loading = true;
    this.reportService.getUserCountryStatistics().subscribe((res:any) => {
      this.countryRate = res;
      this.loading = false;
    })
  }

  openTrafficLightInfoModal() {
    const dialogRef = this.dialog.open(InformationModalComponent, {
      width: '500px',
      data: {title: this.statisticIndicator.shortMemo, msg: this.statisticIndicator.longMemo}
    });
  }
}
