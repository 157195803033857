import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { AuthGuard } from './modules/auth/guard/auth.guard';
import { AuthInterceptorService } from './modules/auth/service/auth-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { AdminGuard } from './modules/auth/guard/admin.guard';
import { LoggedGuard } from './modules/auth/guard/logged.guard';
import { ExceptionInterceptorService } from './shared/interceptors/exception-interceptor.service';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    DashboardModule,
    ToastrModule.forRoot(),
    FlexLayoutModule,
    SharedModule
  ],
  providers: [
    AuthGuard,
    AdminGuard,
    LoggedGuard,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ExceptionInterceptorService, multi: true},
    ThemeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
