import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.css']
})
export class GoBackComponent implements OnInit {
  @Input() navigateTo;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goBack() {
    if (this.navigateTo) {
      this.router.navigate([this.navigateTo]);
    } else {
      history.back();
    }
  }

}
