import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    private headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json'});
    private loginUrl: string;
    private loginWithTokenUrl: string;
    private apiUrl: string;

    constructor(private httpClient: HttpClient, private router: Router) {
        this.apiUrl = environment.apiBaseUrl;
        this.loginUrl = this.apiUrl + "login";
        this.loginWithTokenUrl = this.apiUrl + "token/login";
    }

    authenticateWithCookie() {
        const body = { };
        return this.httpClient.post(
            this.loginWithTokenUrl,
            JSON.stringify(body),
            { observe: 'response', withCredentials: true }
            )
            .pipe(
                tap((res: any) => {
                    if(res.body.enabled && res.body.active) {
                        let bearerToken = res.headers.get('Authorization');
                        let role = res.body.role.role;
                        let id = res.body.id;
                        let departmentId = res.body.department.id;
        
                        sessionStorage.setItem('username', 'username'); //TBD
                        sessionStorage.setItem('token', bearerToken);
                        sessionStorage.setItem('role', role);
                        sessionStorage.setItem('loggedUserId', id);
                        sessionStorage.setItem('loggedUserDepartmentId', departmentId);
        
        
                        return res;
                    }           
                })
            )
    }

    authenticateWithToken(token: string) {
        const body = { };
        return this.httpClient.post(
            this.loginWithTokenUrl,
            JSON.stringify(body),
            { headers: {'Authorization': `Bearer ${token}`}, observe: 'response' }
            )
            .pipe(
                tap((res: any) => {
                    if(res.body.enabled && res.body.active) {
                        let bearerToken = res.headers.get('Authorization');
                        let role = res.body.role.role;
                        let id = res.body.id;
                        let departmentId = res.body.department.id;
        
                        sessionStorage.setItem('username', 'username'); //TBD
                        sessionStorage.setItem('token', bearerToken);
                        sessionStorage.setItem('role', role);
                        sessionStorage.setItem('loggedUserId', id);
                        sessionStorage.setItem('loggedUserDepartmentId', departmentId);
        
        
                        return res;
                    }           
                })
            )
    }

    authenticate(username: string, password: string, isAdmin:boolean = false) {
        let accountCredentials = {username: username, password: password, isAdmin: isAdmin};
        return this.httpClient.post(this.loginUrl,
            JSON.stringify(accountCredentials),
            {headers: this.headers, observe: 'response'}
        )
        .pipe(map((res: any) => {
            if(res.body.enabled && res.body.active) {
                let bearerToken = res.headers.get('Authorization');
                let role = res.body.role.role;
                let id = res.body.id;
                let departmentId = res.body.department.id;                

                sessionStorage.setItem('username', username);
                sessionStorage.setItem('token', bearerToken);
                sessionStorage.setItem('role', role);
                sessionStorage.setItem('loggedUserId', id);
                sessionStorage.setItem('loggedUserDepartmentId', departmentId);


                return res;
            }
        }))
    }



    isAuthenticated(): boolean {
        const user = sessionStorage.getItem('username');
        const token = sessionStorage.getItem('token');

        if(user && token) {
            const tokenInfo = decode(token);
            const date = new Date(0).setUTCSeconds(tokenInfo.exp);

            return (date.valueOf() > new Date().valueOf());
        }

        return false;
    }

    isUserLoggedIn() {
        let user = sessionStorage.getItem('username');
        return !(user === null)
    }

    logOut() {
        this.httpClient.post(this.apiUrl + 'logout',  {headers: this.headers})
          .subscribe();
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('role');
        // this.router.navigate(['/login']);
        
        window.location.href = environment.portalUrl;
    }

    springLogOut() {
        let id = sessionStorage.getItem('loggedUserId');
    }

    isUserAppAdmin() {
        let role = sessionStorage.getItem('role');
        if(role === 'ROLE_APP_ADMIN') {
            return true;
        }

        return false;
    }

    getLoggedUser() {
        let id = sessionStorage.getItem('loggedUserId');
        return id;
    }

    getLoggedUserDepartmentId() {
        let id = sessionStorage.getItem('loggedUserDepartmentId');
        return id;
    }

    isUserExist(email: string) {
        const param = {email: email};
        return this.httpClient
          .get(this.apiUrl + "publicresource/user/exist", {headers: this.headers, params: param})
          .pipe(map(res => { return res as boolean}));
    }

    requestPasswordReset(email: string) {
        return this.httpClient
            .post(this.apiUrl + "publicresource/password/reset?email=" + email, {headers: this.headers})
            .pipe(map((res: any) => res as any))

    }

    preValidatePasswordResetRequest(params: any) {
        return this.httpClient
          .get(this.apiUrl + "publicresource/password/prevalidation", {headers: this.headers, params: params})
          .pipe(map(res => { return res as boolean}));
    }

    validatePasswordResetRequest(params: any) {
        return this.httpClient
          .get(this.apiUrl + "publicresource/password/validation", {headers: this.headers, params: params})
          .pipe(map(res => { return res as boolean}));
    }

    changePassword(body:any) {
        return this.httpClient
            .post(this.apiUrl + "publicresource/password/change", body, {headers: this.headers})
            .pipe(map((res: any) => res as any))
    }
    updatePassword(body:any) {
        return this.httpClient
            .post(this.apiUrl + "publicresource/password/update", body, {headers: this.headers})
            .pipe(map((res: any) => res as any))
    }
    changeUserPassword(body:any) {
        return this.httpClient
            .post(this.apiUrl + "user/changePassword", body, {headers: this.headers})
            .pipe(map((res: any) => res as any))
    }
    preValidateAdminInvitationProcess(params: any) {
        return this.httpClient
            .get(this.apiUrl + "publicresource/invitation/admin/prevalidation", {headers: this.headers, params: params})
            .pipe(map(res => { return res as boolean}));
    }

    getInvitation(params: any) {
        return this.httpClient
            .get(this.apiUrl + "publicresource/invitation/admin/get", {headers: this.headers, params: params})
            .pipe(map(res => { return res as any}));
    }




}
