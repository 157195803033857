import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Type } from '../models/type';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { AuthService } from '../modules/auth/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TypesService {

  private _currentMorphologyTypesScope: BehaviorSubject<Type[]> = new BehaviorSubject<Type[]>(null);
  public readonly CurrentMorphologyTypesScope = this._currentMorphologyTypesScope.asObservable();

  private _currentTopographyTypesScope: BehaviorSubject<Type[]> = new BehaviorSubject<Type[]>(null);
  public readonly CurrentTopographyTypesScope = this._currentTopographyTypesScope.asObservable();

  private _currentTumorStageTypesScope: BehaviorSubject<Type[]> = new BehaviorSubject<Type[]>(null);
  public readonly CurrentTumorStageTypesScope = this._currentTumorStageTypesScope.asObservable();

  private apiUrl: string;
  private headers = { 'Content-Type': 'application/json'};
  private userId:any = null;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.apiUrl = environment.apiBaseUrl;
    this.userId = authService.getLoggedUser();
  }

  getMorphologyTypes() {
    const params = {
      userId: this.userId
    }

    return this.httpClient
      .get(this.apiUrl + "types/morphology/getAll", { headers: this.headers, params: params })
      .pipe(map((res: any) => { 
        this._currentMorphologyTypesScope.next(res); 
        return res as Type[];
      }))
  }

  getTopographyTypes() {
    const params = {
      userId: this.userId
    }

    return this.httpClient
      .get(this.apiUrl + "types/topography/getAll", { headers: this.headers, params: params })
      .pipe(map((res: any) => { 
        this._currentTopographyTypesScope.next(res);
        return res as Type[];
      }))
  }

  getTumorStageTypes() {
    const params = {
      userId: this.userId
    }

    return this.httpClient
      .get(this.apiUrl + "types/tumorStage/getAll", { headers: this.headers, params: params })
      .pipe(map((res: any) => { 
        this._currentTumorStageTypesScope.next(res);
        return res as Type[];
      }))
  }

}
