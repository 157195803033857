import { Component, OnInit, Input } from '@angular/core';
import { IhcIshInstitutionRatio } from 'src/app/models/ihc-ish-institution-ratio';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-institutions-positive-ratio-chart',
  templateUrl: './institutions-positive-ratio-chart.component.html',
  styleUrls: ['./institutions-positive-ratio-chart.component.css']
})
export class InstitutionsPositiveRatioChartComponent implements OnInit {

  @Input() ratios:IhcIshInstitutionRatio[];

  constructor() { }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
          maxBarThickness: 70,
          scaleLabel: {
            display: true,
            labelString: 'Institutions'
         }
          // barThickness: 70
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 0,
          stepSize: 10,
        },
        scaleLabel: {
          display: true,
          labelString: 'Positivity Rate %'
       }
      }]
    }
  };
 
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartLabels = [];
  public barChartData = [{data: []}];
  public barChartColors: Color[] = [{ backgroundColor: 'rgb(32, 134, 7)' }]

  ngOnInit() {
    if(this.ratios.length > 0) {
      this.setBarValues();
    }
  }

  setBarValues() {
    this.ratios.forEach(ratio => {
      this.barChartLabels.push(ratio.diagramInstitutionName);
      this.barChartData[0].data.push(ratio.ihcRatio);
    })
  }

}
