import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/service/auth.service';
import { DataManagementService } from 'src/app/service/data-management.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {
  @Input() showMenu = false;
  @Input() showBackButton = true;
  @Input() subtitle = '';
  @Input() navigateTo = '';
  @Input() navigateHint = '';
  @Input() title = 'HER2';
  @Input() titleLeft = '205px';
  @Input() titleColor = '#0060df';

  menuOpened = false;
  userCanInvite = false;


  constructor(
    private router: Router,
    private authService: AuthService,
    private dataManagementService: DataManagementService
  ) { }

  ngOnInit() {
    this.userCanInvite = sessionStorage.getItem("canInvite") == "true";
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  isUserLogged(): boolean {
    return this.authService.isUserLoggedIn();
  }

  onLogout() {
    const isAdmin:boolean = this.authService.isUserAppAdmin();
    if(isAdmin) {
      this.authService.logOut();
      this.router.navigate(['admin/login']);
    } else {
      this.authService.logOut();
    }
  }

  downloadInformationFile() {
    const params: any = {
      informationFileType: "INFORMATION_FILE"
    };

    this.dataManagementService.getInformationFile(params).subscribe((res:any) => {
      this.saveInformationFile(res);
    })
  }

  private saveInformationFile(res:any) {
    let filename = res.headers.get('Content-Disposition');
    let correctFilename = filename.replace('filename=', '');

    var blob = new Blob([res.body], {type: "application/pdf"});
    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = correctFilename;
    a.click();
  }

}
