import { FormGroup } from '@angular/forms';

export function TimeFrameValidator(timeFrameFormGroup:string, timeFrameType:string, wholeYear:string ) {
    return (formGroup: FormGroup) => {
        const insideForm:FormGroup = formGroup.controls[timeFrameFormGroup] as FormGroup;
        const timeFrameTypeFlag = insideForm.controls[timeFrameType].value;

        if(timeFrameTypeFlag) {
            insideForm.controls[wholeYear].setErrors(null);
        }  
        
        if(!timeFrameTypeFlag) {
            const year = insideForm.controls[wholeYear].value;
            if(year == null) {
                insideForm.controls[wholeYear].setErrors({required: true});
            } else {
                insideForm.controls[wholeYear].setErrors(null);
            }
        }
    }
}