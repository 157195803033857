import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Institution } from '../models/institution';

@Injectable({
    providedIn: 'root'
  })
  export class DataManagementService {

    private apiUrl: string;
    private headers = { 'Content-Type': 'application/json'};
    private multipartheaders = { 'Content-Type': 'multipart/mixed'};
    private plainHeaders = { 'Accept': 'text/plain'};

    constructor(private httpClient: HttpClient) {
      this.apiUrl = environment.apiBaseUrl;
    }

    uploadSheetFile(formData: any) {
        return this.httpClient
            .post(this.apiUrl + "upload/sheetFile", formData)
            .pipe(map((res: any) => res as any))
    }

    saveSheetFileEntries(body: any) {
      return this.httpClient
            .post(this.apiUrl + "save/sheetFileEntries", body)
            .pipe(map((res: any) => res as any))
    }

    getSheetFile(params: any) {
      return this.httpClient
        .get(this.apiUrl + "download/sheetFile", {observe: 'response', responseType: "blob", params: params})
        .pipe(map((res: any) => res as any));
    }

    getFullSheetFile() {
      return this.httpClient
        .get(this.apiUrl + "download/sheetFile/full", {observe: 'response', responseType: "blob"})
        .pipe(map((res: any) => res as any));
    }

    getInformationFile(params: any) {
      return this.httpClient
        .get(this.apiUrl + "download/information/files", {observe: 'response', responseType: "blob", params: params})
        .pipe(map((res: any) => res as any));
    }

  }