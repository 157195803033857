import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleComponent } from './components/title/title.component';
import { GoBackComponent } from './components/go-back/go-back.component';

import {MatTabsModule} from '@angular/material/tabs';
import { ButtonOptionsComponent } from './components/button-options/button-options.component';
import { RouterModule } from '@angular/router';
import { TimeIntervalStartComponent } from '../modules/dashboard/shared/time-interval-start/time-interval-start.component';
import { TimeIntervalEndComponent } from '../modules/dashboard/shared/time-interval-end/time-interval-end.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';


const Components = [
  TitleComponent,
  GoBackComponent,
  ButtonOptionsComponent,
  TimeIntervalStartComponent,
  TimeIntervalEndComponent
]

@NgModule({
  declarations: [Components],
  imports: [
    CommonModule,
    MatTabsModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    Components,
    MatTabsModule
  ]
})
export class SharedModule { }
