import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormUtilComponent } from 'src/app/utils/form-util.component';
import { ComparisonValidator } from 'src/app/shared/validators/comparison.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/service/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends FormUtilComponent implements OnInit {

  @ViewChild('changePasswordSuccessMsg', {static: false}) changePasswordSuccessMsg: ElementRef;
  @ViewChild('changePasswordErrorMsg', {static: false}) changePasswordErrorMsg: ElementRef;

  public passwordChangeFormGroup: FormGroup;
  public submitted = false;
  
  private id:number = null;
  private token:string = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router, 
    private toastr: ToastrService,
    private location:  Location
  ) {
    super();
    this.passwordChangeFormGroup = this.getPasswordChangeForm();
  }

  ngOnInit() {
    
  }

  onSubmit() {
    this.submitted = true;

    if (this.passwordChangeFormGroup.valid) {
      let newPassword = this.getPasswordChangeFormValue().newPassword
      this.requestPasswordChange(newPassword);
    }
  }

  private requestPasswordChange(newPassword: string) {
    const body = {
      userId: this.authService.getLoggedUser(),
      password: newPassword
    };

    this.authService.changeUserPassword(body).subscribe(res => {
      this.location.back();
      this.toastr.success(this.changePasswordSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.changePasswordErrorMsg.nativeElement.textContent);
    })
  }

  private getPasswordChangeFormValue() {
    const passwords = {
      newPassword: this.getInputValue(this.passwordChangeFormGroup, 'newPasswordInputCtrl'),
      newPasswordRepeadet: this.getInputValue(this.passwordChangeFormGroup, 'newPasswordRepeatedInputCtrl')
    }

    return passwords;
  }

  private getPasswordChangeForm() {

    return this.formBuilder.group({
      newPasswordInputCtrl: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
      newPasswordRepeatedInputCtrl: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]]
    }, {
      validator: ComparisonValidator('newPasswordInputCtrl', 'newPasswordRepeatedInputCtrl')
    });

  }
}
