import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Entry } from 'src/app/models/entry';
import { PatientService } from 'src/app/service/patient.service';
import { AuthService } from '../../auth/service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { faMale, faFemale, faPlus, faMinus, faCog, faEdit, faTrash, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-owner-entries',
  templateUrl: './owner-entries.component.html',
  styleUrls: ['./owner-entries.component.css']
})
export class OwnerEntriesComponent implements OnInit {
  @ViewChild('deleteEntrySuccessMsg', {static: false}) deleteEntrySuccessMsg: ElementRef;
  @ViewChild('deleteEntryErrorMsg', {static: false}) deleteEntryErrorMsg: ElementRef;

  @ViewChild('deleteMultipleEntriesSuccessMsg', {static: false}) deleteMultipleEntriesSuccessMsg: ElementRef;
  @ViewChild('deleteMultipleEntriesErrorMsg', {static: false}) deleteMultipleEntriesErrorMsg: ElementRef;

  public faMale = faMale;
  public faFemale = faFemale;
  public faMinus = faMinus;
  public faPlus = faPlus;
  public faCog = faCog;
  public faEdit = faEdit;
  public faTrash = faTrash;
  public faInfoCircle = faInfoCircle;
  public faQuestionCircle = faQuestionCircle;

  public loading = false;
  public allChekedFlag:boolean = false;
  public showActionButtonsFlag:boolean = false;

  public actualNumber:number;
  public totalElements:number;
  public totalPages:number;
  public actualSize:number;

  public entries:any[] = [];

  constructor(
    private patientService: PatientService, 
    private authService: AuthService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loadEntries();
  }

  goBack() {
    this.location.back();
  }

  openCreateSingleRecordPage() {
    this.router.navigate(['entries/create/single']);
  }

  openCreateMultipleRecordsPage() {
    this.router.navigate(['entries/create/multiple']);
  }

  paginatorModification(value:any) {
    this.loadEntries(value.size, value.page);
  }

  getEntries() {
    this.loading = true;
    this.patientService.CurrentUserEntriesScope.subscribe((res: any[]) => {
      this.entries = res;
      if (res == null) {
        this.loadEntries();
      } else {
        this.entries = res;
        this.loading = false;
      }
    })
  }

  loadEntries(size=10, page=0, sort="name", query=null) {
    this.loading = true;
    const params: any = {
      userId: this.authService.getLoggedUser()
    };

    this.patientService.getEntriesByUserId(params).subscribe((res:any) => {
      this.entries = res;
      if(this.entries != null) {
        this.entries.sort((a,b)=> {
          if(a.orderNumber > b.orderNumber) {
            return 1;
          } else if(a.orderNumber < b.orderNumber) {
            return -1;
          } else {
            return 0;
          }
        })
      }

      this.loading = false;
    });
  }

  setupPaginator(res: any) {
    this.actualNumber = res.pagination.number;
    this.totalElements = res.pagination.totalElements;
    this.totalPages = res.pagination.totalPages;
    this.actualSize = res.pagination.size;
  }

  redirectToDetails(entry: any) {
    this.router.navigate([entry.bulkEntryId], {relativeTo: this.route});
  }

  removePatients() {
    let id = this.authService.getLoggedUser();
    this.patientService.removePatient(id).subscribe(res => {
      this.loadEntries();
    })
  }

  openDeleteConfirmationModal(entry: any) {
    const MSG = "Permanently delete your entry? You can't undo this.";
    const TITLE = "Delete entry"
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {msg: MSG, title: TITLE}
    });
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.removeEntry(entry);
      }
    });
  }

  openDeleteMultipleEntriesConfirmationModal() {
    const MSG = "Permanently delete selected entries? You can't undo this.";
    const TITLE = "Delete selected entries"
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {msg: MSG, title: TITLE}
    });
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.removeSelectedEntries();
      }
    });
  }

  removeEntry(entry: any) {
    let userId = this.authService.getLoggedUser();

    this.patientService.removeBulkEntry(entry.bulkEntryId, userId).subscribe(res => {
      this.toastr.success(this.deleteEntrySuccessMsg.nativeElement.textContent);
      this.loadEntries();
    },err => {
      this.toastr.error(this.deleteEntryErrorMsg.nativeElement.textContent);
    })
  }

  checkAllAction() {
    this.allChekedFlag = !this.allChekedFlag;
    let elements: any[] = this.entries;
    elements.forEach(x => x.checked = this.allChekedFlag);
    this.showHideActionButtons();
  }

  checkAction(item:any, event:any) {
    let element:any = this.entries.find(x => x.bulkEntryId == item.bulkEntryId);

    if(element.checked == undefined || (element.checked !== undefined && element.checked == false)) {
      element.checked = true;
    } else {
      element.checked = false;
    }
    if(this.allChekedFlag) {
      this.allChekedFlag = false;
    }
    this.showHideActionButtons();
  }

  showHideActionButtons() {
    let elements: any[] = this.entries;
    let anyElements = elements.find(x => x.checked === true);
    if(anyElements !== undefined || (anyElements !== undefined && anyElements !== null)) {
      this.showActionButtonsFlag = true;
    } else {
      this.showActionButtonsFlag = false;
    }
  }

  removeSelectedEntries() {
    let elements: any[] = this.entries;
    let checkedElements:any[] = elements.filter(x => x.checked === true);
    let checkedIds:any[] = checkedElements.map(r => r.bulkEntryId);

    if(checkedIds !== null && checkedIds.length > 0) {
      this.deleteMultipleEntries(checkedIds);
    }
  }

  deleteMultipleEntries(checkedIds: any[]) {
    let userId = this.authService.getLoggedUser();

    const body = {
      bulkEntriesId: checkedIds,
      userId: userId
    }

    this.loading = true;
    this.patientService.removeMultipleBulkEntry(body).subscribe((res:any) => {
      this.loadEntries();
      this.toastr.success(this.deleteMultipleEntriesSuccessMsg.nativeElement.textContent);
      this.loading = false;
    }, err => {
      this.toastr.error(this.deleteMultipleEntriesErrorMsg.nativeElement.textContent);
    })
  }

}
