import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
        if(!this.authService.isAuthenticated()) {
            this.router.navigate(['/admin/login']);
            return false;
        } else {
            return this.isUserAppAdmin();
        }
    }

    isUserAppAdmin():boolean {
        if(this.authService.isUserAppAdmin()) {
            return true;
        } else {
            this.router.navigate(["/admin/login"]);
            return false;
        }
    }

}