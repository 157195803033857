import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/service/auth.service';
import { DataManagementService } from 'src/app/service/data-management.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {

  title = 'hdc';
  public loading:boolean = false;

  constructor(
    private router: Router, 
    private authService: AuthService,
    private dataManagementService: DataManagementService
    ) { }

  ngOnInit() {
  }

  onLogin() {
    this.router.navigate(['/login']);
  }

  isUserLogged(): boolean {
    return this.authService.isUserLoggedIn();
  }

  relocateToDashboard(){
    this.router.navigate(['dashboard']);
  }

  navigateToDataManagementSpace() {
    this.router.navigate(['data-management']);
  }

  onLogout() {
    const isAdmin:boolean = this.authService.isUserAppAdmin();
    if(isAdmin) {
      this.authService.logOut();
      this.router.navigate(['admin/login']);
    } else {
      this.authService.logOut();
    }
  }

  downloadInformationFile() {
    const params: any = {
      informationFileType: "INFORMATION_FILE"
    };
    
    this.loading = true;
    this.dataManagementService.getInformationFile(params).subscribe((res:any) => {
      this.saveInformationFile(res)
      this.loading = false;
    })
    
  }

  private saveInformationFile(res:any) {
    let filename:string = res.headers.get('Content-Disposition');
    let correctFilename = filename.replace('filename=', '');

    var blob = new Blob([res.body], {type: "application/pdf"});
    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = correctFilename;
    a.click();
  }
 changePassword(){
  this.router.navigate(['dashboard/changePassword']);
 }
}
