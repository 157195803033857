import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoreType } from 'src/app/utils/enums/score-type';
import { IshDoneType } from 'src/app/utils/enums/ish-done-type';
import { IshPositivieType } from 'src/app/utils/enums/ish-positive-type';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormUtilComponent } from 'src/app/utils/form-util.component';
import { TestIhc } from 'src/app/models/entry';

@Component({
  selector: 'app-add-test-ihc-modal',
  templateUrl: './add-test-ihc-modal.component.html',
  styleUrls: ['./add-test-ihc-modal.component.css']
})
export class AddTestIhcModalComponent extends FormUtilComponent implements OnInit {

  public ihcFormGroup: FormGroup
  public editMode:boolean = false;
  public testIhc:TestIhc = null;
  public submitted = false;

  public scoreTypes = ScoreType;
  public scoreKeys:string[] = [];
  public filteredScoreTypes: Observable<string[]>;

  public ishDoneTypes = IshDoneType;
  public ishDoneKeys:string[] = [];
  public filteredIshDoneTypes: Observable<string[]>;

  public ishPositiveTypes = IshPositivieType;
  public ishPositiveKeys:string[] = [];
  public filteredIshPositiveTypes: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddTestIhcModalComponent>,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setEditValues()
    this.ihcFormGroup = this.formBuilder.group(this.getIhcForm());

    this.scoreKeys = Object.keys(this.scoreTypes);
    this.ishDoneKeys = Object.keys(this.ishDoneTypes);
    this.ishPositiveKeys = Object.keys(this.ishPositiveTypes);
   }

  ngOnInit() {
    this.filteredScoreTypes = this.ihcFormGroup.get('scoreInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.scoreKeys, value)));
    this.filteredIshDoneTypes = this.ihcFormGroup.get('ishDoneInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.ishDoneKeys, value)));
    this.filteredIshPositiveTypes = this.ihcFormGroup.get('ishPositiveInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.ishPositiveKeys, value)));
  }

  private setEditValues() {
    if(this.data) {
      this.editMode = this.data.editMode;
      this.testIhc = this.data.testIhc;
    }
  }

  private getIhcForm() {
    return {
      scoreInputCtrl: [this.editMode ? this.scoreTypes[this.testIhc.score] : '', [Validators.required]],
      ishDoneInputCtrl: [this.editMode ? this.ishDoneTypes[this.testIhc.ishDone] : '', [Validators.required]],
      ishPositiveInputCtrl: [this.editMode ? this.ishPositiveTypes[this.testIhc.ishPositive] : '', [Validators.required]],
    }
  }

  private getIhcFormValue() {
    const ihcData = {
      score: this.getInputValue(this.ihcFormGroup, 'scoreInputCtrl'),
      ishDone: this.getInputValue(this.ihcFormGroup, 'ishDoneInputCtrl'),
      ishPositive: this.getInputValue(this.ihcFormGroup, 'ishPositiveInputCtrl'),
    }

    if(ihcData.score) {
      let scoreValue = Object.keys(this.scoreTypes).filter(x => this.scoreTypes[x] === ihcData.score);
      ihcData.score = scoreValue[0];
    }

    if(ihcData.ishDone) {
      let ishDoneValue = Object.keys(this.ishDoneTypes).filter(x => this.ishDoneTypes[x] === ihcData.ishDone);
      ihcData.ishDone = ishDoneValue[0];
    }

    if(ihcData.ishPositive){
      let ishPositiveValue = Object.keys(this.ishPositiveTypes).filter(x => this.ishPositiveTypes[x] === ihcData.ishPositive);
      ihcData.ishPositive = ishPositiveValue[0];
    }
    
    return ihcData;
  }

  private typeFilter(filterCase:string[], value: string): string[] {
    const filterValue = this.normalizeFilteredValue(value);
    return filterCase.filter(type => this.normalizeFilteredValue(type).includes(filterValue));
  }

  private normalizeFilteredValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }  

  get controls() { return this.ihcFormGroup.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.ihcFormGroup.valid) {
      this.dialogRef.close(this.getIhcFormValue());
    } else {
      return;
    }
  }

}
