import { FormGroup } from '@angular/forms';

export function EntryIshValidator(ishCount:string, ishPositive:string, ishEquivocal:string ) {
    return (formGroup: FormGroup) => {

        const ishCountCtrl = formGroup.controls[ishCount]
        const ishPositiveCtrl = formGroup.controls[ishPositive];
        const ishEquivocalCtrl = formGroup.controls[ishEquivocal];
        
        if((ishCountCtrl.errors && (ishCountCtrl.errors.required || ishCountCtrl.errors.min))) {
            return;
        }

        let ishCountValue = 0;
        let ishPositiveValue = 0;
        let ishEquivocalValue = 0;

        if(ishCountCtrl.value != null) {
            ishCountValue = Number(ishCountCtrl.value);
        }

        if(ishPositiveCtrl.value != null) {
            ishPositiveValue = Number(ishPositiveCtrl.value);
        }

        if(ishEquivocalCtrl.value != null) {
            ishEquivocalValue = Number(ishEquivocalCtrl.value);
        }

        if((ishPositiveValue + ishEquivocalValue) > ishCountValue) {
            ishCountCtrl.setErrors({ishCountSum: true});
            ishPositiveCtrl.setErrors({ishCountSum: true});
            ishEquivocalCtrl.setErrors({ishCountSum: true});
        } else {
            ishCountCtrl.setErrors(null);
            ishPositiveCtrl.setErrors(null);
            ishEquivocalCtrl.setErrors(null);
        }
    }
}