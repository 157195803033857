import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from 'src/app/service/patient.service';
import { Area } from 'src/app/models/area';
import { MatDialog } from '@angular/material/dialog';
import { AddClusterModalComponent } from '../shared/add-cluster-modal/add-cluster-modal.component';
import { Cluster } from 'src/app/models/cluster';
import { PatientEmitter } from 'src/app/shared/emitter/patient-emitter';
import { ToastrService } from 'ngx-toastr';
import { AddAreaModalComponent } from '../shared/add-area-modal/add-area-modal.component';

@Component({
  selector: 'app-patient-area-details',
  templateUrl: './patient-area-details.component.html',
  styleUrls: ['./patient-area-details.component.css']
})
export class PatientAreaDetailsComponent implements OnInit {

  @ViewChild('areaUpdatedSuccessMsg', {static: false}) areaUpdatedSuccessMsg: ElementRef;
  @ViewChild('areaUpdatedErrorMsg', {static: false}) areaUpdatedErrorMsg: ElementRef;
  @ViewChild('clusterCreatedSuccessMsg', {static: false}) clusterCreatedSuccessMsg: ElementRef;
  @ViewChild('clusterCreatedErrorMsg', {static: false}) clusterCreatedErrorMsg: ElementRef;

  public loading: boolean = false;
  public areaId:string = null;

  public area:Area = null;
  public clusters:Cluster[] = null;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    public dialog: MatDialog,
    private patientEmitter: PatientEmitter,
    private toastr: ToastrService
  ) {
    this.areaId = this.activatedRoute.snapshot.paramMap.get("id");
    this.patientEmitter.reloadAreaDetails.subscribe(reload => this.getArea());
   }

  ngOnInit() {
    this.getArea();
  }

  goBack() {
    this.location.back();
  }

  getArea() {
    this.loading = true;

    this.patientService.getArea(this.areaId).subscribe((res:Area) => {
      this.area = res;
      this.loading = false;
    })
  }

  loadClusters(size=10, page=0, sort=null, query=null) {
    this.loading = true;
    const params: any = {
      size: size,
      page: page,
      sort: sort,
      areaId: this.areaId
    };

    if(query != null) {
      params.query = query;
    }

    this.patientService.getClusters(params).subscribe((res:any) => {
      this.clusters = res.entry;
      this.loading = false;
    })
  }

  openEditAreaModal() {
    const dialogRef = this.dialog.open(AddAreaModalComponent, {
      width: '500px', data: {area: this.area, editMode: true}
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.updateArea(this.area.id, result);
      }
    });
  }

  updateArea(id:number, body:any) {
    this.patientService.updateArea(id, body).subscribe((res:Area) => {
      this.getArea();
      this.toastr.success(this.areaUpdatedSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.areaUpdatedErrorMsg.nativeElement.textContent);
    })
  }

  openClusterModal() {
    const dialogRef = this.dialog.open(AddClusterModalComponent, {
      width: '500px', data: {cluster: null, editMode: false}
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.createCluster(result);
      }
    });
  }

  createCluster(result:any) {
    const body = {
      clusterNumber: result.clusterNumber,
      her2: result.her2,
      cep17: result.cep17,
      area: "areas/" + this.areaId
    }

    this.patientService.createCluster(body).subscribe((res:Cluster) => {
      this.patientEmitter.reloadCluster.next(true);
      this.getArea();
      this.toastr.success(this.clusterCreatedSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.clusterCreatedErrorMsg.nativeElement.textContent);
    })

  }

}
