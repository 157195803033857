import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { FormUtilComponent } from 'src/app/utils/form-util.component';
import { EntryIshValidator } from 'src/app/shared/validators/entry-ish.validator';
import { EntryValidator } from 'src/app/shared/validators/entry.validator';
import { MatDatepicker } from '@angular/material/datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {Moment} from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TimeFrameValidator } from 'src/app/shared/validators/time-frame.validator';
import { Location } from '@angular/common';
import { PatientService } from 'src/app/service/patient.service';

import { GenderType } from 'src/app/utils/enums/gender-type';
import { ToastrService } from 'ngx-toastr';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-entries-form-preview',
  templateUrl: './entries-form-preview.component.html',
  styleUrls: ['./entries-form-preview.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntriesFormPreviewComponent extends FormUtilComponent implements OnInit {

  @Input() 
  inputEntry:any;
  @Input()
  editMode:boolean;
  @Output() 
  bulkEntry = new EventEmitter<any>();

  public entry: any = null;
  public entries: any[] = null;
  public info: any = null;
  public testInfo: any = null;

  public loading:boolean = false;

  public entryFormGroup: FormGroup;
  public entryZeroFormGroup: FormGroup;
  public entryOneFormGroup: FormGroup;
  public entryTwoFormGroup: FormGroup;
  public entryThreeFormGroup: FormGroup;
  public entryIshOnlyFormGroup: FormGroup;

  public rows: FormArray;
  public itemForm: FormGroup;
  public isFormValid:boolean = true;
  public timeFrameFlag:boolean = false;
  public submittedInitialSettings = false;
  public submittedZero = false;
  public submittedOne = false;
  public submittedTwo = false;
  public submittedThree = false;
  public submittedIshOnly = false;

  public genderTypes = GenderType;
  private gender = null;
  private year = null;
  private fromDate = null;
  private toDate = null;
  
  private countZero = null;
  private ishCountZero = null;
  private ishEquivocalZero = null;
  private ishPositiveZero = null;
  private countOne = null;
  private ishCountOne = null;
  private ishEquivocalOne = null;
  private ishPositiveOne = null;
  private countTwo = null;
  private ishCountTwo = null;
  private ishEquivocalTwo = null;
  private ishPositiveTwo = null;
  private countThree = null;
  private ishCountThree = null;
  private ishEquivocalThree = null;
  private ishPositiveThree = null;
  private countIshOnly = null;
  private ishCountIshOnly = null;
  private ishEquivocalIshOnly = null;
  private ishPositiveIshOnly = null;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private patientService: PatientService,
    private toastr: ToastrService
  ) {
    super();
   }

  ngOnInit() {
    this.loading = true;
    this.info = this.inputEntry.info;
    this.testInfo = this.inputEntry.testInfo;
    this.entries = this.inputEntry.entryDtoList;
    this.entry = this.inputEntry.entryDtoList[0];
    
    if(this.entries.length > 0) {
      this.init();
    }
    
    this.entryFormGroup = this.getEntryForm();
    this.entryZeroFormGroup = this.getEntryZeroFormGroup();
    this.entryOneFormGroup = this.getEntryOneFormGroup();
    this.entryTwoFormGroup = this.getEntryTwoFormGroup();
    this.entryThreeFormGroup = this.getEntryThreeFormGroup();
    this.entryIshOnlyFormGroup = this.getEntryIshOnlyFormGroup();
    this.loading = false;
  }

  goBack() {
    this.location.back();
  }

  init() {
    if(this.testInfo.year !== null) {
      this.year = this.yearToMoment(this.testInfo.year, true);
      this.timeFrameFlag = false;
    } else {
      this.fromDate = this.yearToMoment(this.testInfo.fromDate, true);
      this.toDate = this.yearToMoment(this.testInfo.toDate, false);
      this.timeFrameFlag = true;
    }

    this.entries.forEach(entry => {
      this.initTestForm(entry);
    })

  }

  private yearToMoment(yearValue:string, start:boolean): Moment {
    let yearString = null;

    if(start) {
      yearString = yearValue + "-01-01T00:00:01"
    } else {
      yearString = yearValue + "-31-12T00:00:01"
    }
    
    let tempYear:Moment = moment(yearString);
    let momentYear = moment();

    return momentYear.year(tempYear.year())
  }

  private initTestForm(entry: any) {
    let zeroValues = entry.resultZero;
    let oneValues = entry.resultOne;
    let twoValues = entry.resultTwo;
    let threeValues = entry.resultThree;
    let ishOnlyValues = entry.resultIshOnly;

    if(zeroValues !== null) {
      this.countZero = (zeroValues.count == 0) ? null : zeroValues.count;
      this.ishCountZero = (zeroValues.ishCount == 0) ? null : zeroValues.ishCount;
      this.ishEquivocalZero = (zeroValues.ishEquivocal == 0) ? null : zeroValues.ishEquivocal;
      this.ishPositiveZero = (zeroValues.ishPositive == 0) ? null : zeroValues.ishPositive;
    }

    if(oneValues !== null) {
      this.countOne = (oneValues.count == 0) ? null : oneValues.count;
      this.ishCountOne = (oneValues.ishCount == 0) ? null : oneValues.ishCount;
      this.ishEquivocalOne = (oneValues.ishEquivocal == 0) ? null : oneValues.ishEquivocal;
      this.ishPositiveOne = (oneValues.ishPositive == 0) ? null : oneValues.ishPositive;
    }

    if(twoValues !== null) {
      this.countTwo = (twoValues.count == 0) ? null : twoValues.count;
      this.ishCountTwo = (twoValues.ishCount == 0) ? null : twoValues.ishCount;
      this.ishEquivocalTwo = (twoValues.ishEquivocal == 0) ? null : twoValues.ishEquivocal;
      this.ishPositiveTwo = (twoValues.ishPositive == 0) ? null : twoValues.ishPositive;
    }

    if(threeValues !== null) {
      this.countThree = (threeValues.count == 0) ? null : threeValues.count;
      this.ishCountThree = (threeValues.ishCount == 0) ? null : threeValues.ishCount;
      this.ishEquivocalThree = (threeValues.ishEquivocal == 0) ? null : threeValues.ishEquivocal;
      this.ishPositiveThree = (threeValues.ishPositive == 0) ? null : threeValues.ishPositive;
    }

    if(ishOnlyValues !== null) {
      this.ishCountIshOnly = (ishOnlyValues.ishCount == 0) ? null : ishOnlyValues.ishCount;
      this.ishEquivocalIshOnly = (ishOnlyValues.ishEquivocal == 0) ? null : ishOnlyValues.ishEquivocal;
      this.ishPositiveIshOnly = (ishOnlyValues.ishPositive == 0) ? null : ishOnlyValues.ishPositive;
    }
  }

  private getEntryForm() {
    return this.formBuilder.group({
      genderInputCtrl: ["N/A", [Validators.required]],
      morphologyInputCtrl: ["N/A", [Validators.required]],
      topographyInputCtrl: ["N/A", [Validators.required]],
      tumorStageInputCtrl: ["N/A", [Validators.required]],
      timeFrameFormGroup: this.formBuilder.group({
        timeFrameTypeInputCtrl: [this.timeFrameFlag],
        wholeYearInputCtrl: [this.year],
        timeIntervalStartInputCtrl: [this.fromDate],
        timeIntervalEndInputCtrl: [this.toDate]
      })
    },{validator: TimeFrameValidator('timeFrameFormGroup','timeFrameTypeInputCtrl', 'wholeYearInputCtrl')});
  }

  private getEntryZeroFormGroup() {
    return this.formBuilder.group({
      countZeroInputCtrl: [this.countZero, [Validators.required, Validators.min(1)]],
      ishZeroFormGroup: this.formBuilder.group({
        ishCountZeroInputCtrl: [this.ishCountZero, [Validators.min(1)]],
        ishPositiveZeroInputCtrl: [this.ishPositiveZero, [Validators.min(0)]],
        ishEquivocalZeroInputCtrl: [this.ishEquivocalZero, [Validators.min(0)]]
      })
    }, {validator: EntryValidator('countZeroInputCtrl', 'ishZeroFormGroup', 'ishCountZeroInputCtrl', 'ishPositiveZeroInputCtrl', 'ishEquivocalZeroInputCtrl')});
  }

  private getEntryOneFormGroup() {
    return this.formBuilder.group({
      countOneInputCtrl: [this.countOne, [Validators.required, Validators.min(1)]],
      ishOneFormGroup: this.formBuilder.group({
        ishCountOneInputCtrl: [this.ishCountOne, [Validators.min(1)]],
        ishPositiveOneInputCtrl: [this.ishPositiveOne, [Validators.min(0)]],
        ishEquivocalOneInputCtrl: [this.ishEquivocalOne, [Validators.min(0)]]
      })
    }, {validator: EntryValidator('countOneInputCtrl', 'ishOneFormGroup', 'ishCountOneInputCtrl', 'ishPositiveOneInputCtrl', 'ishEquivocalOneInputCtrl')});
  }

  private getEntryTwoFormGroup() {
    return this.formBuilder.group({
      countTwoInputCtrl: [this.countTwo, [Validators.required, Validators.min(1)]],
      ishTwoFormGroup: this.formBuilder.group({
        ishCountTwoInputCtrl: [this.ishCountTwo, [Validators.min(1)]],
        ishPositiveTwoInputCtrl: [this.ishPositiveTwo, [Validators.min(0)]],
        ishEquivocalTwoInputCtrl: [this.ishEquivocalTwo, [Validators.min(0)]]
      })
    }, {validator: EntryValidator('countTwoInputCtrl', 'ishTwoFormGroup', 'ishCountTwoInputCtrl', 'ishPositiveTwoInputCtrl', 'ishEquivocalTwoInputCtrl')});
  }

  private getEntryThreeFormGroup() {
    return this.formBuilder.group({
      countThreeInputCtrl: [this.countThree, [Validators.required, Validators.min(1)]],
      ishThreeFormGroup: this.formBuilder.group({
        ishCountThreeInputCtrl: [this.ishCountThree, [Validators.min(1)]],
        ishPositiveThreeInputCtrl: [this.ishPositiveThree, [Validators.min(0)]],
        ishEquivocalThreeInputCtrl: [this.ishEquivocalThree, [Validators.min(0)]]
      })
    }, {validator: EntryValidator('countThreeInputCtrl', 'ishThreeFormGroup', 'ishCountThreeInputCtrl', 'ishPositiveThreeInputCtrl', 'ishEquivocalThreeInputCtrl')});
  }

  private getEntryIshOnlyFormGroup() {
    return this.formBuilder.group({
      ishOnlyCountInputCtrl: [this.ishCountIshOnly, [Validators.required, Validators.min(1)]],
      ishOnlyPositiveInputCtrl: [this.ishPositiveIshOnly, [Validators.required, Validators.min(1)]],
      ishOnlyEquivocalInputCtrl: [this.ishEquivocalIshOnly, [Validators.required, Validators.min(1)]]
    }, {validator: EntryIshValidator('ishOnlyCountInputCtrl', 'ishOnlyPositiveInputCtrl', 'ishOnlyEquivocalInputCtrl')});
  }

  private getEntryFormInput() {
    let timeFrames = this.getInputValue(this.entryFormGroup, 'timeFrameFormGroup');
    const data: any = {
      gender: this.getInputValue(this.entryFormGroup, 'genderInputCtrl'),
      morphology: this.getInputValue(this.entryFormGroup, 'morphologyInputCtrl'),
      topography: this.getInputValue(this.entryFormGroup, 'topographyInputCtrl'),
      tumorStage: this.getInputValue(this.entryFormGroup, 'tumorStageInputCtrl'),
      timeInterval: timeFrames.timeFrameTypeInputCtrl,
      year: timeFrames.wholeYearInputCtrl,
      startDate: timeFrames.timeIntervalStartInputCtrl,
      endDate: timeFrames.timeIntervalEndInputCtrl
    }
    return data;
  }

  private getEntryZeroFormInput() {
    let ishsZero = this.getInputValue(this.entryZeroFormGroup, 'ishZeroFormGroup');
    const data: any = {
      count: this.getInputValue(this.entryZeroFormGroup, 'countZeroInputCtrl'),
      ishCount: ishsZero.ishCountZeroInputCtrl,
      ishPositive: ishsZero.ishPositiveZeroInputCtrl,
      ishEquivocal: ishsZero.ishEquivocalZeroInputCtrl
    }
    return data;
  }

  private getEntryOneFormInput() {
    let ishsOne = this.getInputValue(this.entryOneFormGroup, 'ishOneFormGroup');
    const data: any = {
      count: this.getInputValue(this.entryOneFormGroup, 'countOneInputCtrl'),
      ishCount: ishsOne.ishCountOneInputCtrl,
      ishPositive: ishsOne.ishPositiveOneInputCtrl,
      ishEquivocal: ishsOne.ishEquivocalOneInputCtrl
    }
    return data;
  }

  private getEntryTwoFormInput() {
    let ishsTwo = this.getInputValue(this.entryTwoFormGroup, 'ishTwoFormGroup');
    const data: any = {
      count: this.getInputValue(this.entryTwoFormGroup, 'countTwoInputCtrl'),
      ishCount: ishsTwo.ishCountTwoInputCtrl,
      ishPositive: ishsTwo.ishPositiveTwoInputCtrl,
      ishEquivocal: ishsTwo.ishEquivocalTwoInputCtrl
    }
    return data;
  }

  private getEntryThreeFormInput() {
    let ishsThree = this.getInputValue(this.entryThreeFormGroup, 'ishThreeFormGroup');
    const data: any = {
      count: this.getInputValue(this.entryThreeFormGroup, 'countThreeInputCtrl'),
      ishCount: ishsThree.ishCountThreeInputCtrl,
      ishPositive: ishsThree.ishPositiveThreeInputCtrl,
      ishEquivocal: ishsThree.ishEquivocalThreeInputCtrl
    }
    return data;
  }

  private getEntryIshOnlyFormInput() {
    const data: any = {
      ishCount: this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyCountInputCtrl'),
      ishPositive: this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyPositiveInputCtrl'),
      ishEquivocal: this.getInputValue(this.entryIshOnlyFormGroup, 'ishOnlyEquivocalInputCtrl')
    }
    return data;
  }

  get controlsInitial() { return this.entryFormGroup.controls; }
  get controlsTimeFrame() { 
    const insideForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    return insideForm.controls; 
  }


  get controlsZero() { return this.entryZeroFormGroup.controls; }
  get controlsOne() { return this.entryOneFormGroup.controls; }
  get controlsTwo() { return this.entryTwoFormGroup.controls; }
  get controlsThree() { return this.entryThreeFormGroup.controls; }
  get controlsIshOnly() { return this.entryIshOnlyFormGroup.controls; }

  onSubmit() {
    this.submittedInitialSettings = true;
    if(!this.entryFormGroup.valid) {
      return;
    }

    this.checkSubmitts();

    if((this.submittedZero && !this.entryZeroFormGroup.valid) || (this.submittedOne && !this.entryOneFormGroup.valid) || (this.submittedTwo && !this.entryTwoFormGroup.valid) 
    || (this.submittedThree && !this.entryThreeFormGroup.valid) || (this.submittedIshOnly && !this.entryIshOnlyFormGroup.valid) ) {
      return;
    }

    let entryInitials = null;
    let zeroValue = null;
    let oneValue = null;
    let twoValue = null;
    let threeValue = null;
    let ishOnlyValue = null;

    entryInitials = this.getEntryFormInput();
    const initialData = {
      gender: entryInitials.gender,
      morphology: entryInitials.morphology,
      topography: entryInitials.topography,
      tumorStage: entryInitials.tumorStage,
      timeInterval: entryInitials.timeInterval,
      year: null,
      startDate: null,
      endDate: null
    }

    if(!initialData.timeInterval && entryInitials.year !== null) {
      initialData.year = entryInitials.year.year();
    }
    if(initialData.timeInterval && entryInitials.startDate !== null && entryInitials.endDate !== null) {
      initialData.startDate = entryInitials.startDate.year();
      initialData.endDate = entryInitials.endDate.year();
    }

    if(this.submittedZero && this.entryZeroFormGroup.valid) {
      zeroValue = this.getEntryZeroFormInput();
    } 
    
    if(this.submittedOne && this.entryOneFormGroup.valid) {
      oneValue = this.getEntryOneFormInput();
    }

    if(this.submittedTwo && this.entryTwoFormGroup.valid) {
      twoValue = this.getEntryTwoFormInput();
    }

    if(this.submittedThree && this.entryThreeFormGroup.valid) {
      threeValue = this.getEntryThreeFormInput();
    }

    if(this.submittedIshOnly && this.entryIshOnlyFormGroup.valid) {
      ishOnlyValue = this.getEntryIshOnlyFormInput()
    }

    if(this.editMode) {
      this.updateEntries(initialData, zeroValue, oneValue, twoValue, threeValue, ishOnlyValue);
    } else {
      this.saveEntries(initialData, zeroValue, oneValue, twoValue, threeValue, ishOnlyValue);
    }
  }

  checkSubmitts() {
    this.checkSubmittedZero();
    this.checkSubmittedOne();
    this.checkSubmittedTwo();
    this.checkSubmittedThree();
    this.checkSubmittedIshOnly();
  }

  checkSubmittedZero() {
    let data = this.getEntryZeroFormInput();
    if(data.count == null && data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedZero = false;
    } else {
      this.submittedZero = true;
    }
  }

  checkSubmittedOne() {
    let data = this.getEntryOneFormInput();
    if(data.count == null && data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedOne = false;
    } else {
      this.submittedOne = true;
    }
  }

  checkSubmittedTwo() {
    let data = this.getEntryTwoFormInput();
    if(data.count == null && data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedTwo = false;
    } else {
      this.submittedTwo = true;
    }
  }

  checkSubmittedThree() {
    let data = this.getEntryThreeFormInput();
    if(data.count == null && data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedThree = false;
    } else {
      this.submittedThree = true;
    }
  }

  checkSubmittedIshOnly() {
    let data = this.getEntryIshOnlyFormInput();
    if(data.ishCount == null && data.ishPositive == null && data.ishEquivocal == null) {
      this.submittedIshOnly = false;
    } else {
      this.submittedIshOnly = true;
    }
  }

  private saveEntries(initialData:any, zeroValue:any, oneValue:any, twoValue:any, threeValue:any, ishOnlyValue:any) {
    
    const body = {
      initialEntryValues: initialData,
      resultZero: zeroValue,
      resultOne: oneValue,
      resultTwo: twoValue,
      resultThree: threeValue,
      resultIshOnly: ishOnlyValue
    }

    this.bulkEntry.emit(body);

    this.patientService.createMultipleEntries(body).subscribe(res => {
      // this.toastr.success(this.createEntriesSuccessMsg.nativeElement.textContent);
      this.goBack();
    }, err => {
      // this.toastr.error(this.createEntriesErrorMsg.nativeElement.textContent);
    })
  }

  private updateEntries(initialData:any, zeroValue:any, oneValue:any, twoValue:any, threeValue:any, ishOnlyValue:any) {
    this.entry
    const body = {
      initialEntryValues: initialData,
      resultZero: zeroValue,
      resultOne: oneValue,
      resultTwo: twoValue,
      resultThree: threeValue,
      resultIshOnly: ishOnlyValue,
      bulkEntryId: this.inputEntry.bulkEntryId
    }

    this.patientService.updateMultipleEntries(body).subscribe(res => {
      // this.toastr.success(this.createEntriesSuccessMsg.nativeElement.textContent);
      this.goBack();
    }, err => {
      // this.toastr.error(this.createEntriesErrorMsg.nativeElement.textContent);
    })


  }

  chosenStartYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    const timeFrameForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    timeFrameForm.get("timeIntervalStartInputCtrl").setValue(ctrlValue);
    datepicker.close();
  }

  chosenEndYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    const timeFrameForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    timeFrameForm.get("timeIntervalEndInputCtrl").setValue(ctrlValue);
    datepicker.close();
  }

  chosenWholeYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    const timeFrameForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    timeFrameForm.get("wholeYearInputCtrl").setValue(ctrlValue);
    datepicker.close();
  }

  changeTimeFrme() {
    const timeFrameForm:FormGroup = this.entryFormGroup.controls["timeFrameFormGroup"] as FormGroup;
    this.timeFrameFlag = !timeFrameForm.get("timeFrameTypeInputCtrl").value;
  }

}
