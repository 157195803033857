import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PatientEmitter } from 'src/app/shared/emitter/patient-emitter';
import { PatientService } from 'src/app/service/patient.service';
import { Area } from 'src/app/models/area';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddAreaModalComponent } from '../shared/add-area-modal/add-area-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patient-area',
  templateUrl: './patient-area.component.html',
  styleUrls: ['./patient-area.component.css']
})
export class PatientAreaComponent implements OnInit {

  @ViewChild('areaUpdatedSuccessMsg', {static: false}) areaUpdatedSuccessMsg: ElementRef;
  @ViewChild('areaUpdatedErrorMsg', {static: false}) areaUpdatedErrorMsg: ElementRef;

  public loading:boolean = false;
  public actualNumber:number;
  public totalElements:number;
  public totalPages:number;
  public actualSize:number;

  public ishId:string;
  public areas:Area[] = [];

  constructor(
    private patientEmitter: PatientEmitter,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.ishId = this.activatedRoute.snapshot.paramMap.get("id");
    this.patientEmitter.reloadArea.subscribe(res => this.loadAreas());
  }

  ngOnInit() {
    this.loadAreas();
  }

  loadAreas(size=10, page=0, sort=null, query=null) {
    this.loading = true;
    const params: any = {
      size: size,
      page: page,
      sort: sort,
      projection: 'areaWithCalculations',
      testIshId: this.ishId
    };

    if(query != null) {
      params.query = query;
    }

    this.patientService.getAreas(params).subscribe((res:any) => {
      this.areas = res.entry;
      this.setupPaginator(res);
      this.loading = false;
    })
  }

  setupPaginator(res: any) {
    this.actualNumber = res.pagination.number;
    this.totalElements = res.pagination.totalElements;
    this.totalPages = res.pagination.totalPages;
    this.actualSize = res.pagination.size;
  }

  paginatorModification(value:any) {
    this.loadAreas(value.size, value.page);
  }

  redirectToDetails(area: Area) {
    this.router.navigate(['area/' + area.id], {relativeTo: this.route});
  }

  openEditAreaModal(area:Area) {
    const dialogRef = this.dialog.open(AddAreaModalComponent, {
      width: '500px', data: {area: area, editMode: true}
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.updateArea(area.id, result);
      }
    });
  }

  updateArea(id:number, body:any) {
    this.patientService.updateArea(id, body).subscribe((res:Area) => {
      this.loadAreas();
      this.toastr.success(this.areaUpdatedSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.areaUpdatedErrorMsg.nativeElement.textContent);
    })
  }

}
