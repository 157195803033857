import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './modules/auth/guard/admin.guard';
import { AuthGuard } from './modules/auth/guard/auth.guard';
import { LoggedGuard } from './modules/auth/guard/logged.guard';


const routes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        canActivate: [LoggedGuard],
        loadChildren: './modules/auth/auth.module#AuthModule'
      },
      {
        path: 'dashboard1',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/dashboard1/dashboard1.module').then(m => m.Dashboard1Module)
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'data-management',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/data-management/data-management.module').then(m => m.DataManagementModule)
      },
      {
        path: 'admin/dashboard',
        canActivate: [AdminGuard],
        loadChildren: () => import('./modules/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule)
      }
    ]

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
