import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/auth/service/auth.service';
import { PatientService } from 'src/app/service/patient.service';
import { Entry } from 'src/app/models/entry';
import { faMale, faFemale, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-main-preview-table',
  templateUrl: './main-preview-table.component.html',
  styleUrls: ['./main-preview-table.component.css']
})
export class MainPreviewTableComponent implements OnInit {

  public faMale = faMale;
  public faFemale = faFemale;
  public faMinus = faMinus;
  public faPlus = faPlus;

  public loading = false;
  public entries:any[] = [];

  constructor(private authService: AuthService, private patientService: PatientService) { }

  ngOnInit() {
    this.getLatestEntries();
  }

  getLatestEntries() {
    this.loading = true;
    let userId = this.authService.getLoggedUser()

    const params: any = {
      userId: this.authService.getLoggedUser()
    };

    this.patientService.getLatestEntriesByUserId(params).subscribe((res:any) => {
      this.entries = res;
      this.loading = false;
    });
  }

}
