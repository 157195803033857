import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { PatientService } from 'src/app/service/patient.service';
import { ActivatedRoute } from '@angular/router';
import { TestIhc } from 'src/app/models/entry';
import { PatientEmitter } from 'src/app/shared/emitter/patient-emitter';
import { ScoreType } from 'src/app/utils/enums/score-type';
import { IshDoneType } from 'src/app/utils/enums/ish-done-type';
import { IshPositivieType } from 'src/app/utils/enums/ish-positive-type';
import { AddTestIhcModalComponent } from '../shared/add-test-ihc-modal/add-test-ihc-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/service/auth.service';

@Component({
  selector: 'app-patient-ihc',
  templateUrl: './patient-ihc.component.html',
  styleUrls: ['./patient-ihc.component.css']
})
export class PatientIhcComponent implements OnInit {

  @ViewChild('updateIhcSuccessMsg', {static: false}) updateIhcSuccessMsg: ElementRef;
  @ViewChild('updateIhcErrorMsg', {static: false}) updateIhcErrorMsg: ElementRef;

  public loading = false;
  public actualNumber:number;
  public totalElements:number;
  public totalPages:number;
  public actualSize:number;

  private patientId:string;
  private ihcs:TestIhc[] = [];

  public scoreTypes = ScoreType;
  public ishDoneTypes = IshDoneType;
  public ishPositiveTypes = IshPositivieType;

  constructor(
    private patientService:PatientService,
    private authService: AuthService, 
    private activatedRoute: ActivatedRoute,
    private patientEmitter: PatientEmitter,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {
    this.patientEmitter.reloadIhc.subscribe(message => this.loadIhc());
    this.patientId = this.activatedRoute.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.loadIhc();
  }

  private loadIhc(size=10, page=0, sort=null, active=null, query=null) {
    this.loading = true;
    const params: any = {
      size: size,
      page: page,
      sort: sort,
      patientId: this.patientId
    };

    if(query) {
      params.query = query;
    }

    this.patientService.getIhcs(params).subscribe((res:any) => {
      this.ihcs = res.entry;
      this.setupPaginator(res);
      this.loading = false;
    });
  }

  setupPaginator(res: any) {
    this.actualNumber = res.pagination.number;
    this.totalElements = res.pagination.totalElements;
    this.totalPages = res.pagination.totalPages;
    this.actualSize = res.pagination.size;
  }

  paginatorModification(value:any) {
    this.loadIhc(value.size, value.page);
  }

  openEditIhcModal(ihc: TestIhc) {
    const dialogRef = this.dialog.open(AddTestIhcModalComponent, {
      width: '500px', data: {editMode: true, testIhc: ihc}
    });
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.updateTestIhc(ihc.id, result);
      }
    });
  }

  private updateTestIhc(id: number, body:any) {
    body.updateDate = new Date();
    body.updatedByUser = "users/" + this.authService.getLoggedUser();

    this.patientService.updateIhc(id, body).subscribe(res => {
      this.loadIhc();
      this.toastr.success(this.updateIhcSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.updateIhcErrorMsg.nativeElement.textContent);
    })
  }

}
