import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {Moment} from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TimeFrameEndsValidator } from 'src/app/shared/validators/time-frame-ends.validator';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-time-interval-start',
  templateUrl: './time-interval-start.component.html',
  styleUrls: ['./time-interval-start.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
})
export class TimeIntervalStartComponent implements OnInit {

  @Input() 
  timeFrameFlag: boolean;

  @Input() 
  editMode: boolean;

  @Input() 
  startDate: any;

  @Input() 
  endDate: any;

  @Input()
  set submitted(value:any) {
    this.onSubmit(value);
  }

  @Output() 
  startInterval = new EventEmitter<any>();

  @Output() 
  endInterval = new EventEmitter<any>();

  @Output() 
  validationErrors = new EventEmitter<any>();

  public submittedInitialSettings:boolean = false;
  public entryStartDate: FormGroup;

  public minDate: Date;
  public maxDate: Date;

  constructor(
    private formBuilder: FormBuilder
  ) {
    

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(1999, 0, 1);
    this.maxDate = new Date(currentYear + 1, 0, 0);
  }

  ngOnInit() {
    this.entryStartDate = this.getEntryStartDateForm();
  }

  // chosenStartYearHandler(normalizedYear: Moment) {
  //   const ctrlValue = moment();
  //   ctrlValue.year(normalizedYear.year());
  //   this.entryStartDate.get("timeIntervalStartInputCtrl").setValue(ctrlValue);
  // }

  // chosenStartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  //   const ctrlValue = moment(this.entryStartDate.get("timeIntervalStartInputCtrl").value);
  //   ctrlValue.month(normalizedMonth.month());
  //   this.entryStartDate.get("timeIntervalStartInputCtrl").setValue(ctrlValue);
  //   datepicker.close();
  //   this.emittStartDate();
  // }

  // chosenEndYearHandler(normalizedYear: Moment) {
  //   const ctrlValue = moment();
  //   ctrlValue.year(normalizedYear.year());
  //   this.entryStartDate.get("timeIntervalEndInputCtrl").setValue(ctrlValue);
  // }

  // chosenEndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  //   const ctrlValue = moment(this.entryStartDate.get("timeIntervalEndInputCtrl").value);
  //   ctrlValue.month(normalizedMonth.month());
  //   this.entryStartDate.get("timeIntervalEndInputCtrl").setValue(ctrlValue);
  //   datepicker.close();
  //   this.emittEndDate();
  // }

  startValueChange(normalizedMonth: Moment) {
    const ctrlValue = moment(this.entryStartDate.get("timeIntervalStartInputCtrl").value);
    this.emittStartDate();
  }

 endValueChange(normalizedMonth: Moment) {
    const ctrlValue = moment(this.entryStartDate.get("timeIntervalEndInputCtrl").value);
    this.emittEndDate();
  }

  private getEntryStartDateForm() {
    return this.formBuilder.group({
      timeIntervalStartInputCtrl: [(this.editMode) ? this.startDate : null],
      timeIntervalEndInputCtrl: [(this.editMode) ? this.endDate : null]
    }, {validators: TimeFrameEndsValidator("timeIntervalStartInputCtrl", "timeIntervalEndInputCtrl", (this.timeFrameFlag) ? "true" : "false", (this.submittedInitialSettings) ? "true" : "false")})
  }

  private emittStartDate() {
    let value = this.entryStartDate.get("timeIntervalStartInputCtrl").value;
    let momentValue:Moment = moment(value, 'YYYY/MM/DD');
    
    let startDate = momentValue.toDate();
    this.startInterval.emit(startDate);
    this.validationErrors.emit(this.entryStartDate);
  }

  private emittEndDate() {
    let value = this.entryStartDate.get("timeIntervalEndInputCtrl").value;
    let momentValue:Moment = moment(value, 'YYYY/MM/DD');

    let endDate = momentValue.toDate();
    this.endInterval.emit(endDate);
    this.validationErrors.emit(this.entryStartDate);
  }

  get controlsInitial() { return this.entryStartDate.controls; }

  onSubmit(value: any) {
    if(value && this.timeFrameFlag) {
      this.submittedInitialSettings = true;
    }
  }

  updateStartChange(value: any) {
    if(this.editMode) {
      let value = this.entryStartDate.get("timeIntervalStartInputCtrl").value;
      let momentValue:Moment = moment(value, 'YYYY/MM/DD');
      let startDate = momentValue.toDate();

      this.startInterval.emit(startDate);
      this.validationErrors.emit(this.entryStartDate);
    }
  }

  updateEndChange(value: any) {
    if(this.editMode) {
      let value = this.entryStartDate.get("timeIntervalEndInputCtrl").value;

      let momentValue:Moment = moment(value, 'YYYY/MM/DD');
      let endDate = momentValue.toDate();
      this.endInterval.emit(endDate);
      this.validationErrors.emit(this.entryStartDate);
    }
  }
}