import {Component} from '@angular/core';
import * as _forEach from 'lodash/forEach';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-util',
  template: '',
  styles: []
})
export class FormUtilComponent {
  protected getInputValue(form: FormGroup, key: string) {
    return form.get(key) ? form.get(key).value : null;
  }

  protected setInputValue(form: FormGroup, key: string, value: any): void {
    form.get(key).setValue(value);
  }
}
