import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/service/auth.service';
import { PatientService } from 'src/app/service/patient.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PatientEmitter } from 'src/app/shared/emitter/patient-emitter';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-entries',
  templateUrl: './edit-entries.component.html',
  styleUrls: ['./edit-entries.component.css']
})
export class EditEntriesComponent implements OnInit {

  public loading = false;
  private bulkEntryId:string;
  private entry:any = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private patientService: PatientService,
    public dialog: MatDialog,
  ) {
    this.bulkEntryId = this.activatedRoute.snapshot.paramMap.get("id");
   }

  ngOnInit() {
    this.loadEntry();
  }

  goBack() {
    this.location.back();
  }

  // getEntry() {
  //   this.loading = true;
  //   this.patientService.CurrentUserEntriesScope.subscribe((res:any[]) => {
  //     let entries = res;
  //     if(entries !== null && entries.length > 0) {
  //       this.entry = entries.find(entry => entry.bulkEntryId == this.bulkEntryId);
  //       this.loading = false;
  //     } else {
  //       this.loadEntry();
  //     }
  //   })
  // }

  private loadEntry() {
    const params: any = {
      bulkEntryId: this.bulkEntryId
    };
    this.loading = true;
    this.patientService.getEntriesByBulkEntryId(params).subscribe((res:any) => {
      if(res !== null) {
        this.entry = res[0];
      }
      this.loading = false;
    })
  }

}
