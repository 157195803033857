import { FormGroup } from '@angular/forms';

export function EntryValidator(count:string, insideFormGroup:string, ishCount:string, ishPositive:string, ishEquivocal:string ) {
    return (formGroup: FormGroup) => {

        const countCtrl = formGroup.controls[count];
        const insideForm:FormGroup = formGroup.controls[insideFormGroup] as FormGroup;
        const ishCountCtrl = insideForm.controls[ishCount]
        const ishPositiveCtrl = insideForm.controls[ishPositive];
        const ishEquivocalCtrl = insideForm.controls[ishEquivocal];

        
        if((countCtrl.errors && (countCtrl.errors.required || countCtrl.errors.min)) || 
            (ishCountCtrl.errors && (ishCountCtrl.errors.required || ishCountCtrl.errors.min)) || 
            (ishPositiveCtrl.errors && (ishPositiveCtrl.errors.required || ishPositiveCtrl.errors.min)) || 
            (ishEquivocalCtrl.errors && (ishEquivocalCtrl.errors.required || ishEquivocalCtrl.errors.min))) {
            return;
        }

        let countValue = Number(countCtrl.value);
        let ishCountValue = Number(ishCountCtrl.value);
        let ishPositiveValue = Number(ishPositiveCtrl.value);
        let ishEquivocalValue = Number(ishEquivocalCtrl.value);



        if((ishPositiveValue + ishEquivocalValue) > ishCountValue) {
            ishCountCtrl.setErrors({ishCountSum: true});
            ishPositiveCtrl.setErrors({ishCountSum: true});
            ishEquivocalCtrl.setErrors({ishCountSum: true});
        } else {
            ishCountCtrl.setErrors(null);
            ishPositiveCtrl.setErrors(null);
            ishEquivocalCtrl.setErrors(null);
        }

        if(countValue < ishCountValue) {
            countCtrl.setErrors({countSum: true});
            ishCountCtrl.setErrors({countSum: true});
        } else {
            countCtrl.setErrors(null);
            ishCountCtrl.setErrors(null);
        }

    }
}