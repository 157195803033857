import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PatientEmitter {
    public reloadIhc = new Subject<boolean>();
    public reloadIsh = new Subject<boolean>();
    public reloadArea = new Subject<boolean>();
    public reloadAreaDetails = new Subject<boolean>();
    public reloadCluster = new Subject<boolean>();
}