import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { MainComponent } from './main/main.component';
import { DashboardRouting } from './dashboard.routing';
import { AppLoaderModule } from 'src/app/shared/app-loader/app-loader.module';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReportsComponent } from './reports/reports.component';
import { PatientComponent } from './patient/patient.component';
import { AddSinglePatientComponent } from './add-single-patient/add-single-patient.component';
import { AddMultiplePatientComponent } from './add-multiple-patient/add-multiple-patient.component';
import { EntriesComponent } from './entries/entries.component';
import { AppPaginatorModule } from 'src/app/shared/app-paginator/app-paginator.module';
import { PatientIshComponent } from './patient-ish/patient-ish.component';
import { PatientIhcComponent } from './patient-ihc/patient-ihc.component';
import { EditPatientPrimaryModalComponent } from './shared/edit-patient-primary-modal/edit-patient-primary-modal.component';
import { EditPatientTumorModalComponent } from './shared/edit-patient-tumor-modal/edit-patient-tumor-modal.component';
import { AddTestIhcModalComponent } from './shared/add-test-ihc-modal/add-test-ihc-modal.component';
import { AddTestIshModalComponent } from './shared/add-test-ish-modal/add-test-ish-modal.component';
import { OtherEntriesComponent } from './other-entries/other-entries.component';
import { OwnerEntriesComponent } from './owner-entries/owner-entries.component';
import { MainPreviewTableComponent } from './shared/main-preview-table/main-preview-table.component';
import { PatientIshDetailsComponent } from './patient-ish-details/patient-ish-details.component';
import { PatientAreaComponent } from './patient-area/patient-area.component';
import { PatientClusterComponent } from './patient-cluster/patient-cluster.component';
import { AddAreaModalComponent } from './shared/add-area-modal/add-area-modal.component';
import { PatientAreaDetailsComponent } from './patient-area-details/patient-area-details.component';
import { AddClusterModalComponent } from './shared/add-cluster-modal/add-cluster-modal.component';

import { ChartsModule } from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InstitutionsPositiveRatioChartComponent } from './shared/institutions-positive-ratio-chart/institutions-positive-ratio-chart.component';
import { GenderPositiveRatioChartComponent } from './shared/gender-positive-ratio-chart/gender-positive-ratio-chart.component';
import { BirthYearChartComponent } from './shared/birth-year-chart/birth-year-chart.component';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { EntriesFormComponent } from './shared/entries-form/entries-form.component';
import { EntriesFormPreviewComponent } from './shared/entries-form-preview/entries-form-preview.component';
import { EditEntriesComponent } from './edit-entries/edit-entries.component';
import { EntriesDataRangePickersComponent } from './shared/entries-data-range-pickers/entries-data-range-pickers.component';
import { TimeIntervalStartComponent } from './shared/time-interval-start/time-interval-start.component';
import { TimeIntervalEndComponent } from './shared/time-interval-end/time-interval-end.component';
import { DepartmentsPositiveRatioChartComponent } from './shared/departments-positive-ratio-chart/departments-positive-ratio-chart.component';
import { DepartmentPositiveRatioYearsChartComponent } from './shared/department-positive-ratio-years-chart/department-positive-ratio-years-chart.component';
import { InformationModalComponent } from './shared/information-modal/information-modal.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    MainComponent,
    UserDashboardComponent,
    ReportsComponent,
    PatientComponent,
    AddSinglePatientComponent,
    AddMultiplePatientComponent,
    EntriesComponent,
    PatientIshComponent,
    PatientIhcComponent,
    EditPatientPrimaryModalComponent,
    EditPatientTumorModalComponent,
    AddTestIhcModalComponent,
    AddTestIshModalComponent,
    OtherEntriesComponent,
    OwnerEntriesComponent,
    MainPreviewTableComponent,
    PatientIshDetailsComponent,
    PatientAreaComponent,
    PatientClusterComponent,
    AddAreaModalComponent,
    PatientAreaDetailsComponent,
    AddClusterModalComponent,
    InstitutionsPositiveRatioChartComponent,
    GenderPositiveRatioChartComponent,
    BirthYearChartComponent,
    ConfirmationModalComponent,
    EntriesFormComponent,
    EntriesFormPreviewComponent,
    EditEntriesComponent,
    EntriesDataRangePickersComponent,
    // TimeIntervalStartComponent,
    // TimeIntervalEndComponent,
    DepartmentsPositiveRatioChartComponent,
    DepartmentPositiveRatioYearsChartComponent,
    InformationModalComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DashboardRouting,
    AppLoaderModule,
    FlexLayoutModule,
    AppPaginatorModule,
    ChartsModule,
    FontAwesomeModule,
    SharedModule
  ],
  entryComponents:[
    EditPatientPrimaryModalComponent,
    EditPatientTumorModalComponent,
    AddTestIhcModalComponent,
    AddTestIshModalComponent,
    AddAreaModalComponent,
    AddClusterModalComponent,
    ConfirmationModalComponent,
    InformationModalComponent
  ]
})
export class DashboardModule { }
