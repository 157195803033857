import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from 'src/app/service/patient.service';
import { TestIsh } from 'src/app/models/entry';
import { AuthService } from '../../auth/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AddTestIshModalComponent } from '../shared/add-test-ish-modal/add-test-ish-modal.component';
import { ToastrService } from 'ngx-toastr';
import { AddAreaModalComponent } from '../shared/add-area-modal/add-area-modal.component';
import { Area } from 'src/app/models/area';
import { PatientEmitter } from 'src/app/shared/emitter/patient-emitter';

@Component({
  selector: 'app-patient-ish-details',
  templateUrl: './patient-ish-details.component.html',
  styleUrls: ['./patient-ish-details.component.css']
})
export class PatientIshDetailsComponent implements OnInit {

  @ViewChild('updateIshSuccessMsg', {static: false}) updateIshSuccessMsg: ElementRef;
  @ViewChild('updateIshErrorMsg', {static: false}) updateIshErrorMsg: ElementRef;
  @ViewChild('areaCreatedSuccessMsg', {static: false}) areaCreatedSuccessMsg: ElementRef;
  @ViewChild('areaCreatedErrorMsg', {static: false}) areaCreatedErrorMsg: ElementRef;

  public loading = false;
  public testIsh: TestIsh = null;

  private ishId:string = null;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private patientService: PatientService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private patientEmitter: PatientEmitter
  ) {
    this.ishId = this.activatedRoute.snapshot.paramMap.get("id");
   }

  ngOnInit() {
    this.getIshInfo();
  }

  goBack() {
    this.location.back();
  }

  getIshInfo() {
    this.loading = true;
    const params: any = {
      id: this.ishId,
      createdByUserId: this.authService.getLoggedUser(),
      projection: 'ishWithUsers'
    };

    this.patientService.getIshByIdAndCreatorId(params).subscribe((res:TestIsh) => {
      this.testIsh = res;
      this.loading = false;
    })
  }

  openEditIshModal() {
    const dialogRef = this.dialog.open(AddTestIshModalComponent, {
      width: '500px', data: {testIsh: this.testIsh, editMode: true}
    });
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.updateTestIsh(this.testIsh.id, result);
      }
    });
  }

  updateTestIsh(id:number, body: any) {
    body.updateDate = new Date();
    body.updatedByUser = "users/" + this.authService.getLoggedUser();
    this.patientService.updateIsh(id, body).subscribe(res => {
      this.getIshInfo();
      this.toastr.success(this.updateIshSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.updateIshErrorMsg.nativeElement.textContent);
    })
  }

  openAreaModal() {
    const dialogRef = this.dialog.open(AddAreaModalComponent, {
      width: '500px'
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result) {
        this.createArea(result);
      }
    });
  }

  createArea(result:any) {
    const body = {
      areaNumber: result.areaNumber,
      testIsh: "testIshes/" + this.ishId
    }

    this.patientService.createArea(body).subscribe((res:Area) => {
      this.patientEmitter.reloadArea.next(true);
      this.toastr.success(this.areaCreatedSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.areaCreatedErrorMsg.nativeElement.textContent);
    })

  }

}
