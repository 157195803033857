import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { PatientService } from 'src/app/service/patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TestIsh } from 'src/app/models/entry';
import { PatientEmitter } from 'src/app/shared/emitter/patient-emitter';
import { MatDialog } from '@angular/material/dialog';
import { AddTestIshModalComponent } from '../shared/add-test-ish-modal/add-test-ish-modal.component';
import { AuthService } from '../../auth/service/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patient-ish',
  templateUrl: './patient-ish.component.html',
  styleUrls: ['./patient-ish.component.css']
})
export class PatientIshComponent implements OnInit {

  @ViewChild('updateIshSuccessMsg', {static: false}) updateIshSuccessMsg: ElementRef;
  @ViewChild('updateIshErrorMsg', {static: false}) updateIshErrorMsg: ElementRef;

  public loading = false;
  public actualNumber:number;
  public totalElements:number;
  public totalPages:number;
  public actualSize:number;

  private patientId:string;
  private ishs:TestIsh[] = [];

  constructor(
    private patientService:PatientService,
    private authService: AuthService, 
    private activatedRoute: ActivatedRoute,
    private patientEmitter: PatientEmitter,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.patientId = this.activatedRoute.snapshot.paramMap.get("id");
    this.patientEmitter.reloadIsh.subscribe(message => this.loadIsh())
  }

  ngOnInit() {
    this.loadIsh();
  }

  private loadIsh(size=10, page=0, sort=null, active=null, query=null) {
    this.loading = true;
    const params: any = {
      size: size,
      page: page,
      sort: sort,
      projection: 'ishWithUsers',
      patientId: this.patientId
    };

    if(query) {
      params.query = query;
    }

    this.patientService.getIshs(params).subscribe((res:any) => {
      this.ishs = res.entry;
      this.setupPaginator(res);
      this.loading = false;
    });
  }

  setupPaginator(res: any) {
    this.actualNumber = res.pagination.number;
    this.totalElements = res.pagination.totalElements;
    this.totalPages = res.pagination.totalPages;
    this.actualSize = res.pagination.size;
  }

  paginatorModification(value:any) {
    this.loadIsh(value.size, value.page);
  }

  openEditIshModal(ish: TestIsh) {
    const dialogRef = this.dialog.open(AddTestIshModalComponent, {
      width: '500px', data: {testIsh: ish, editMode: true}
    });
  
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if(result) {
        this.updateTestIsh(ish.id, result);
      }
    });
  }

  updateTestIsh(id:number, body: any) {
    body.updateDate = new Date();
    body.updatedByUser = "users/" + this.authService.getLoggedUser();
    this.patientService.updateIsh(id, body).subscribe(res => {
      this.loadIsh();
      this.toastr.success(this.updateIshSuccessMsg.nativeElement.textContent);
    }, err => {
      this.toastr.error(this.updateIshErrorMsg.nativeElement.textContent);
    })
  }

  redirectToDetails(ish: TestIsh) {
    this.router.navigate(['ish/' + ish.id], {relativeTo: this.route});
  }

}
