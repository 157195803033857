import { Component, OnInit, Inject } from '@angular/core';
import { FormUtilComponent } from 'src/app/utils/form-util.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Patient } from 'src/app/models/patient';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TopographyType } from 'src/app/utils/enums/topography-type';
import { MorphologyType } from 'src/app/utils/enums/morphology-type';
import { TumorStageType } from 'src/app/utils/enums/tumor-stage-type';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-patient-tumor-modal',
  templateUrl: './edit-patient-tumor-modal.component.html',
  styleUrls: ['./edit-patient-tumor-modal.component.css']
})
export class EditPatientTumorModalComponent extends FormUtilComponent implements OnInit {

  public editTumorInfoFormGroup: FormGroup
  public patient:Patient;
  public submitted = false;

  public topographyTypes = TopographyType;
  public topographyKeys:string[] = [];
  public filteredTopographyTypes: Observable<string[]>;

  public morphologyTypes = MorphologyType;
  public morphologyKeys:string[] = [];
  public filteredMorphologyTypes: Observable<string[]>;

  public tumorStageTypes = TumorStageType;
  public tumorStageKeys:string[] = [];
  public filteredTumorStageTypes: Observable<string[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPatientTumorModalComponent>,
    private formBuilder: FormBuilder
  ) 
  {
    super();
    this.patient = this.data.patient;

    this.topographyKeys = Object.keys(this.topographyTypes);
    this.morphologyKeys = Object.keys(this.morphologyTypes);
    this.tumorStageKeys = Object.keys(this.tumorStageTypes);

    this.editTumorInfoFormGroup = this.formBuilder.group(this.getTumorInfoForm());
  }

  ngOnInit() {
    this.filteredTopographyTypes = this.editTumorInfoFormGroup.get('topographyInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.topographyKeys, value)));
    this.filteredMorphologyTypes = this.editTumorInfoFormGroup.get('morphologyInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.morphologyKeys, value)));
    this.filteredTumorStageTypes = this.editTumorInfoFormGroup.get('tumorStageInputCtrl').valueChanges.pipe(startWith(''), map(value => this.typeFilter(this.tumorStageKeys, value)));
  }

  private getTumorInfoForm() {
    return {
      topographyInputCtrl: [this.topographyTypes[this.patient.topography]],
      morphologyInputCtrl: [this.morphologyTypes[this.patient.morphology]],
      tumorStageInputCtrl: [this.tumorStageTypes[this.patient.tumorStage], [Validators.required]],
      topographicLocalisationInputCtrl: [this.patient.topographicLocalisation],
      notesInputCtrl: [this.patient.notes],
    }
  }

  private typeFilter(filterCase:string[], value: string): string[] {
    const filterValue = this.normalizeFilteredValue(value);
    return filterCase.filter(type => this.normalizeFilteredValue(type).includes(filterValue));
  }

  private normalizeFilteredValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
  
  getAdminLoginFormValue() {
    const patientEditionData = {
      topography: this.getInputValue(this.editTumorInfoFormGroup, 'topographyInputCtrl'),
      morphology: this.getInputValue(this.editTumorInfoFormGroup, 'morphologyInputCtrl'),
      tumorStage: this.getInputValue(this.editTumorInfoFormGroup, 'tumorStageInputCtrl'),
      topographicLocalisation: this.getInputValue(this.editTumorInfoFormGroup, 'topographicLocalisationInputCtrl'),
      notes: this.getInputValue(this.editTumorInfoFormGroup, 'notesInputCtrl')
    }

    if(patientEditionData.topography) {
      let topographyValue = Object.keys(this.topographyTypes).filter(x => this.topographyTypes[x] === patientEditionData.topography);
      patientEditionData.topography = topographyValue[0];
    } else {
      patientEditionData.topography = null;
    } 

    if(patientEditionData.morphology) {
      let morphologyValue = Object.keys(this.morphologyTypes).filter(x => this.morphologyTypes[x] === patientEditionData.morphology);
      patientEditionData.morphology = morphologyValue[0];
    } else {
      patientEditionData.morphology = null;
    }

    if(patientEditionData.tumorStage) {
      let tumorStageValue = Object.keys(this.tumorStageTypes).filter(x => this.tumorStageTypes[x] === patientEditionData.tumorStage);
      patientEditionData.tumorStage = tumorStageValue[0];
    } else {
      patientEditionData.tumorStage = null;
    }

    return patientEditionData;
  }

  get controls() { return this.editTumorInfoFormGroup.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.editTumorInfoFormGroup.valid) {
      this.dialogRef.close(this.getAdminLoginFormValue());
    } else {
      return;
    }
  }

}
